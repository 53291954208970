import React from "react";

const PText = ({ text, className = "" }) => {
  return (
    <>
      <p
        className={`text-sm font-light text-left font-montserrat tracking-wider text-black dark:text-white ${className}`}>
        {text}
      </p>
    </>
  );
};

export default PText;
