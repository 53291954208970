import React from "react";

// Icons
import { AiOutlinePlus } from "react-icons/ai";

const AddNewButton = ({ onClicks, title }) => {
  return (
    <button
      title="Add new"
      aria-label="add"
      onClick={onClicks}
      className="flex justify-center items-center border bg-transparent hover:bg-system-green border-system-green dark:border-white text-black dark:text-white hover:text-white text-sm lg:text-base font-normal tracking-wider rounded-md cursor-pointer p-1 px-2">
      <AiOutlinePlus />
      <p className="hidden md:flex">{title}</p>
    </button>
  );
};

export default AddNewButton;
