import React, { useState } from "react";

// Language
import { useTranslation } from "react-i18next";

// Icon
import { AiOutlinePlus } from "react-icons/ai";

// Components
import EmployeeForm from "./EmployeeForm";
import ModalLayout from "../../../layout/ModalLayout";
import SuccessModal from "../../successModal/SuccessModal";

const PlusAddNew = () => {
  /* ----- Language ----- */
  const { t } = useTranslation();

  /* ----- Open modal ----- */
  const [open, setOpen] = useState(false);

  /* ----- Open success modal ----- */
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  return (
    <>
      {/* ----- Modal Button ----- */}
      <div
        onClick={() => setOpen(!open)}
        className="fixed w-11 h-11 flex justify-center items-center bg-system-green text-white text-center border border-transparent hover:border-white rounded-full shadow-lg shadow-blue-400/20 cursor-pointer right-8 md:right-10 bottom-8 md:bottom-12 z-10">
        <AiOutlinePlus className="text-4xl font-normal animate-pulse" />
      </div>
      {/* ----- Modal Container ----- */}
      <ModalLayout trigger={open} setTrigger={setOpen}>
        <EmployeeForm
          open={open}
          setOpen={setOpen}
          showSuccessModal={showSuccessModal}
          setShowSuccessModal={setShowSuccessModal}
        />
      </ModalLayout>
      {/* ----- Success Modal ----- */}
      <SuccessModal
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
      />
    </>
  );
};

export default PlusAddNew;
