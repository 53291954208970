import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

// Language
import { useTranslation } from "react-i18next";

// Chart JS
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const FactoryChart = () => {
  /* ----- Language ----- */
  const { t } = useTranslation();

  /* ----- React App Api URL ----- */
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  /* ----- Privilege Admin & User ----- */
  const { user } = useSelector((state) => state.auth);

  /* ----- Employee data ----- */
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = async () => {
    const response = await axiosInstance.get("/employees");
    setEmployees(response.data);
  };

  /* ----- Counter ----- */
  const factory = employees.reduce((acc, employee) => {
    return {
      ...acc,
      [employee.work]: (acc[employee.work] || 0) + 1,
    };
  }, []);

  /* ----- Chart JS ----- */
  var data = {
    labels: [],
    datasets: [
      {
        label: "",
        data: factory,
        barPercentage: 0.5, // Szélesség
        //barThickness: 40,
        backgroundColor: [
          "rgba(0, 69, 138, 0.7)", // Blue
          "rgba(235, 255, 76, 0.5)", // Yellow
          "rgba(255, 0, 255,0.5)", // Pink
          "rgba(255, 116, 0, 0.7)", // Orange
          "rgba(153, 102, 255, 0.5)", // Violet
          "rgba(0, 122, 0, 0.5)", // Green
          "rgba(251,2,2,0.5)", // Red
          "rgba(16,1,255,0.5)", // Dark Blue
          "rgba(1,255,11,0.5)", // Green
          "rgba(255,203,1,0.7)", // Yellow
          "rgba(165,1,255,0.7)", // Violet
          "rgba(0,212,255,0.5)", // Sky Blue
          "rgba(149,255,1,0.5)", // Yelow-Green
        ],
        borderColor: [
          "rgba(0, 69, 138, 1)", // Blue
          "rgba(235, 255, 73, 1)", // Yellow
          "rgba(255, 0, 255, 1)", // Pink
          "rgba(255, 116, 0, 1)", // Orange
          "rgba(153, 102, 255, 1)", // Violet
          "rgba(0, 122, 0, 1)", // Green
          "rgba(251,2,2,1)", // Red
          "rgba(16,1,255,1)", // Dark Blue
          "rgba(1,255,11,1)", // Green
          "rgba(255,203,1,1)", // Yellow
          "rgba(165,1,255,1)", // Violet
          "rgba(0,212,255,1)", // Sky Blue
          "rgba(149,255,1,1)", // Yelow-Green
        ],
        borderWidth: 1,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
    },
    title: { text: "Factoryyyy", display: false },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        scaleLabel: {
          labelString: "Factory",
          display: false,
        },
      },
      x: {
        scaleLabel: {
          labelString: "Factory",
          display: false,
        },
      },
    },
  };

  return (
    <div className="w-full h-[35vh] min-h-[35vh] bg-white dark:bg-slate-900 rounded-md pb-8 p-4 shadow-lg shadow-blue-400/20">
      <h2 className="text-center text-base md:text-2xl text-black dark:text-white font-normal font-mont">
        {t("Statistic.Candidates")}
      </h2>
      <Bar data={data} options={options} />
    </div>
  );
};

export default FactoryChart;
