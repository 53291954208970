import React from "react";
import { useNavigate } from "react-router-dom";

// Language
import { useTranslation } from "react-i18next";

// Icon
import { AiOutlineArrowLeft } from "react-icons/ai";

const GoBackButton = () => {
  const navigate = useNavigate();
  /* ----- Language ----- */
  const { t } = useTranslation();

  return (
    <button
      onClick={() => navigate(-1)}
      className="w-fit h-fit flex justify-center items-center bg-transparent rounded-md border border-transparent hover:border-system-green text-base text-black dark:text-white cursor-pointer p-1 gap-1">
      <AiOutlineArrowLeft /> {t("Campaigns.Back")}
    </button>
  );
};

export default GoBackButton;
