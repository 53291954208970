import React, { useState } from "react";

// Language
import { useTranslation } from "react-i18next";

// Components
import ModalLayout from "../../layout/ModalLayout";

const ForgotPasswordModal = () => {
  /* ----- Language ----- */
  const { t } = useTranslation();

  /* ----- Open Modal ----- */
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      {/* ----------Forgot Password Modal ------------- */}
      <div
        className="flex justify-center items-center text-white cursor-pointer mb-4 "
        onClick={toggleModal}
      >
        <h4 className="text-lg font-extralight ">
          {t("Login.Forgot_password")}
        </h4>
      </div>
      {/* ---------- Modal ------------- */}
      {modal && (
        <div className=" w-fit h-fit fixed flex flex-col justify-center items-center bg-black/70 backdrop-blur-lg rounded-md border border-white inset-0 m-auto p-4 z-10 ">
          <div className="w-full text-right cursor-pointer" onClick={toggleModal}>
            X
          </div>
          <div className=" flex flex-col justify-center items-center">
            <h2 className="text-xl font-light text-center">
              {t("Login.Forgot_password")}
            </h2>
            <h2 className="text-xl font-light text-center">
              {t("Login.Write_Email")}
            </h2>
          </div>
          {/*------------------- Button -----------------------*/}
          <div
            className="text-sm font-light cursor-pointer pt-4"
            onClick={toggleModal}
          >
            {t("Login.Close")}
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPasswordModal;
