import React from "react";
import { Link } from "react-router-dom";

// Icon
import { FiEdit } from "react-icons/fi";

const EditButton = ({ editUrl, text, className = "" }) => {
  return (
    <Link to={editUrl}>
      <button
        aria-label="Edit button"
        className={`w-fit h-fit flex flex-row justify-center items-center bg-transparent hover:bg-system-green border border-system-green rounded-md text-system-green dark:text-white hover:text-white text-center text-base font-normal tracking-wider leading-none transition-all duration-150 hover:shadow-lg hover:shadow-blue-400/20 p-[6px] gap-1 ${className}`}>
        <FiEdit />
        {text}
      </button>
    </Link>
  );
};

export default EditButton;
