import React, { useEffect, useRef } from "react";

// Animation
import Aos from "aos";
import "aos/dist/aos.css";

const ModalLayout = (props) => {
  /* ----- Animation ----- */
  useEffect(() => {
    Aos.init();
  }, []);

  /* ----- Click Outside to Close ----- */
  const menuRef = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        props.setTrigger(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    };
  });

  return props.trigger ? (
    <section
      id="modal-section"
      className="fixed w-full h-screen flex justify-center items-center backdrop-blur-sm bg-black/60 inset-0 z-10">
      <div
        ref={menuRef}
        className="relative w-[95%] md:w-full md:max-w-lg h-fit bg-white dark:bg-slate-900 rounded-md border border-white shadow-xl shadow-blue-400/20 p-5"
        data-aos="zoom-in"
        data-aos-duration="300">
        <span
          className="absolute text-base text-black font-light dark:text-white hover:text-red-500 top-2 right-2 z-10"
          onClick={() => props.setTrigger(false)}>
          X
        </span>
        {props.children}
      </div>
    </section>
  ) : null;
};

export default ModalLayout;
