import React from "react";

const H2Text = ({ text, className = "" }) => {
  return (
    <>
      <h2
        className={`text-2xl font-light text-center font-montserrat tracking-wider text-black dark:text-white ${className}`}>
        {text}
      </h2>
    </>
  );
};

export default H2Text;
