import React from "react";

const SelectLabel = ({ errors, title }) => {
  return (
    <label
      className={`absolute bg-white dark:bg-slate-900 peer-focus:bg-white peer-placeholder-shown:bg-white dark:peer-focus:bg-slate-900 dark:peer-placeholder-shown:bg-slate-900 ${
        errors
          ? "text-red-700 dark:text-red-700 dark:peer-placeholder-shown:text-red-700 peer-focus:text-system-green dark:peer-focus:text-white"
          : "text-gray-700 dark:text-white dark:peer-placeholder-shown:text-gray-300 peer-focus:text-system-green dark:peer-focus:text-white"
      } peer-focus:text-sm peer-placeholder-shown:text-base text-sm peer-focus:font-normal peer-placeholder-shown:font-light font-normal tracking-wider text-opacity-80 transition-all duration-600 top-0 peer-focus:top-0 peer-placeholder-shown:top-1/2 -translate-y-1/2 left-3 pointer-events-none px-1 peer-focus:px-1 peer-placeholder-shown:p-0`}>
      {title}
    </label>
  );
};

export default SelectLabel;
