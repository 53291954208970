import React from "react";

const H3Text = ({ text, className = "" }) => {
  return (
    <>
      <h3
        className={`text-xl font-light text-center font-montserrat tracking-wider text-black dark:text-white ${className}`}>
        {text}
      </h3>
    </>
  );
};

export default H3Text;
