import React from "react";

// Language
import { useTranslation } from "react-i18next";

const FormButtons = ({ submitEvent, onClicks, onErrors }) => {
  /* ----- Language ----- */
  const { t } = useTranslation();
  return (
    <div className="w-full h-fit flex flex-row justify-between items-center gap-4">
      <button
        type="submit"
        onClick={onErrors}
        className={`w-full h-8 bg-system-green hover:bg-transparent border border-system-green text-white dark:text-white hover:text-black dark:hover:text-white text-base font-normal tracking-wider rounded-md`}>
        {t("UI.Save")}
      </button>
      <button
        type="button"
        onClick={onClicks}
        className={`w-full h-8 bg-transparent hover:bg-slate-300 border border-gray-400 hover:border-slate-300 text-black dark:text-white hover:text-black drk:hover:text-black text-base font-normal tracking-wider rounded-md`}>
        {t("UI.Close")}
      </button>
    </div>
  );
};

export default FormButtons;
