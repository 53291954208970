import React from "react";

const H4Text = ({ text, className = "" }) => {
  return (
    <>
      <h4
        className={`text-base font-normal text-left font-montserrat tracking-wider text-black dark:text-white ${className}`}>
        {text}
      </h4>
    </>
  );
};

export default H4Text;
