import React from "react";

// Icons
import { AiOutlineDelete } from "react-icons/ai";

const DateRangeFilter = ({ Date1, Date2, setDate1, setDate2 }) => {
  return (
    <div className="w-fit h-fit flex flex-row justify-center items-center gap-2 md:gap-4">
      <input
        type="date"
        id="date"
        className="w-full bg-transparent text-base font-light text-black dark:text-white border-b border-gray-200 pl-1"
        value={Date1}
        onChange={(e) => setDate1(e.target.value)}
      />
      <input
        type="date"
        id="date"
        className="w-full bg-transparent text-base font-light text-black dark:text-white border-b border-gray-200 pl-1"
        value={Date2}
        onChange={(e) => setDate2(e.target.value)}
      />
      <div className="text-system-green dark:text-white text-xl cursor-pointer">
        <AiOutlineDelete
          onClick={() => {
            setDate1("");
            setDate2("");
          }}
        />
      </div>
    </div>
  );
};

export default DateRangeFilter;
