import React, { useEffect, useState } from "react";

const MessagesList = ({ t, axiosInstance }) => {
  /* ----- Messages ----- */
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    getMessages();
  }, []);

  const getMessages = async () => {
    const response = await axiosInstance.get("/messages");
    setMessages(response.data);
  };

  return (
    <div className="w-full md:w-2/5 min-w-[300px] h-[35vh] min-h-[35vh] flex flex-col justify-start items-center bg-[#f4f39e] rounded-md  shadow-lg shadow-blue-400/20 overflow-y-scroll no-scrollbar p-4 gap-2">
      <h2 className="text-center text-2xl text-system-green font-normal font-mont">
        {t("Messages.Messages")}
      </h2>
      {messages
        .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1)) // Sort by DESC & Last edited first
        .map((message, index) => (
          <div
            key={index}
            className="w-full border-b border-gray-300 break-all">
            <p className="text-center text-lg text-black font-light tracking-wide">
              {message.messageText}
            </p>
          </div>
        ))}
    </div>
  );
};

export default MessagesList;
