import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

// Language
import { useTranslation } from "react-i18next";

// Components
import EmployeeList from "../dashboardLayout/EmployeeList";
import DataChart from "../charts/DataChart";
import MessagesList from "../dashboardLayout/MessagesList";
import FactoryChart from "../charts/FactoryChart";
import StatCardMothly from "../dashboardLayout/StatCardMothly";
import StatCardTotal from "../dashboardLayout/StatCardTotal";
import InfoCard from "../dashboardLayout/InfoCard";
import CardSkeleton from "../skeletons/CardSkeleton";

const DashboardLayout = () => {
  /* ----- Privilege Admin & User ----- */
  const { user } = useSelector((state) => state.auth);

  /* ----- Language ----- */
  const { t } = useTranslation();

  /* ----- React App Api URL ----- */
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  /* ----- Employee data ----- */
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  /* ----- Method fetching data ----- */
  const getEmployees = async () => {
    const response = await axiosInstance.get("/employees");
    setEmployees(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <section
      id="dashboard-section"
      className="w-full h-fit flex flex-col justify-start items-start gap-4">
      {/* ----- Top ----- */}
      <section
        id="top-section"
        className="w-full flex flex-col md:flex-row justify-stretch items-center gap-4">
        {isLoading ? (
          <CardSkeleton className="w-full md:w-1/3 h-44 rounded-md shadow-lg shadow-blue-400/20" />
        ) : (
          <InfoCard t={t} user={user} />
        )}
        {isLoading ? (
          <CardSkeleton className="w-full md:w-1/3 h-44 rounded-md shadow-lg shadow-blue-400/20" />
        ) : (
          <StatCardTotal t={t} employees={employees} isLoading={isLoading} />
        )}
        {isLoading ? (
          <CardSkeleton className="w-full md:w-1/3 h-44 rounded-md shadow-lg shadow-blue-400/20" />
        ) : (
          <StatCardMothly t={t} employees={employees} />
        )}
      </section>
      <section
        id="center-section"
        className="w-full min-h-[35vh] flex flex-col md:flex-row justify-stretch items-center gap-4">
        {isLoading ? (
          <CardSkeleton className="w-full md:w-2/5 min-w-[300px] h-[35vh] min-h-[35vh] rounded-md shadow-lg shadow-blue-400/20" />
        ) : (
          <MessagesList t={t} axiosInstance={axiosInstance} />
        )}
        {isLoading ? (
          <CardSkeleton className="w-full h-[35vh] min-h-[35vh] rounded-md shadow-lg shadow-blue-400/20" />
        ) : (
          <Fragment>
            {user && user.role === "admin" ? <DataChart /> : <FactoryChart />}
          </Fragment>
        )}
      </section>
      {/* ----- Bottom ----- */}
      <section
        id="bottom-section"
        className="w-full flex flex-col md:flex-row justify-stretch items-center gap-4">
        {isLoading ? (
          <CardSkeleton className="w-full h-[40vh] min-h-[40vh] rounded-md shadow-lg shadow-blue-400/20" />
        ) : (
          <EmployeeList t={t} user={user} employees={employees} />
        )}
      </section>
    </section>
  );
};

export default DashboardLayout;
