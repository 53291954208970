import React from "react";

const Inputs = ({ values, onChanges, ids, names, title, types, errors }) => {
  return (
    <div className="relative w-full h-full flex flex-col">
      <input
        id={ids}
        name={names}
        type={types}
        placeholder=" "
        className={`peer w-full h-10 bg-white dark:bg-slate-900 border focus:border-2 ${
          errors
            ? "border-red-700 dark:border-red-700 focus:border-system-green dark:focus:border-white hover:border-system-green text-red-700 dark:text-red-700"
            : "border-gray-500/50 dark:border-gray-300 focus:border-system-green dark:focus:border-white hover:border-system-green text-black dark:text-white"
        } text-base  font-normal tracking-wider rounded-md outline-none transition-all duration-600 indent-3`}
        value={values}
        onChange={onChanges}
      />
      <label
        className={`absolute bg-white dark:bg-slate-900 peer-focus:bg-white peer-placeholder-shown:bg-white dark:peer-focus:bg-slate-900 dark:peer-placeholder-shown:bg-slate-900 ${
          errors
            ? "text-red-700 dark:text-red-700 dark:peer-placeholder-shown:text-red-700 peer-focus:text-system-green dark:peer-focus:text-white peer-placeholder-shown:font-normal"
            : "text-gray-700 dark:text-white dark:peer-placeholder-shown:text-gray-300 peer-focus:text-system-green dark:peer-focus:text-white peer-placeholder-shown:font-light"
        } peer-focus:text-sm peer-placeholder-shown:text-base text-sm peer-focus:font-normal font-normal tracking-wider text-opacity-80 transition-all duration-600 top-0 peer-focus:top-0 peer-placeholder-shown:top-1/2 -translate-y-1/2 left-3 pointer-events-none px-1 peer-focus:px-1 peer-placeholder-shown:p-0`}>
        {title}
      </label>
    </div>
  );
};

export default Inputs;
