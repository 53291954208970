import React from "react";

// Layout
import Layout from "../../components/layout/Layout";

// Protected route
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import { useEffect } from "react";

// Components
import DashboardLayout from "../../components/layout/DashboardLayout";


const Dashboard = () => {
  /* ----- Protected route ----- */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  /* ----- Redirect ----- */
  useEffect(() => {
    if (isError) {
      navigate("/"); // Redirect to login screen
    }
  }, [isError, navigate]);

  return (
    <Layout>
      <DashboardLayout />
    </Layout>
  );
};

export default Dashboard;
