import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Components
import Loading from "./components/loading/Loading";

// Pages
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Messages from "./pages/messages/Messages";

// Lazy loaded elements
const Users = lazy(() => import("./pages/users/Users"));

const FormEditUser = lazy(() => import("./components/formUser/FormEditUser"));
const Employee = lazy(() => import("./pages/employee/Employee"));
const EditEmployee = lazy(() =>
  import("./components/formEmployee/EditEmployee")
);
const Partners = lazy(() => import("./pages/partners/Partners"));
const Works = lazy(() => import("./pages/works/Works"));
const FormEditWork = lazy(() => import("./components/formWork/FormEditWork"));
const Mail = lazy(() => import("./pages/mail/Mail"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const ExportData = lazy(() => import("./pages/exportData/ExportData"));
const Settings = lazy(() => import("./pages/settings/Settings"));
const Statistics = lazy(() => import("./pages/statistics/Statistics"));
const FormEditPartner = lazy(() =>
  import("./components/formPartner/FormEditPartner")
);
const Version = lazy(() => import("./components/settings/Version"));
const Positions = lazy(() => import("./pages/positions/Positions"));
const Tasks = lazy(() => import("./pages/tasks/Tasks"));
const Logs = lazy(() => import("./pages/logs/Logs"));
const UserSettings = lazy(() => import("./pages/settings/UserSettings"));
const EditAgeSettings = lazy(() =>
  import("./components/settings/age/EditAgeSettings")
);
const Subscribers = lazy(() => import("./pages/subscribers/Subscribers"));
const AddNewSubscriber = lazy(() =>
  import("./pages/subscribers/AddNewSubscriber")
);
const Campaigns = lazy(() => import("./pages/campaigns/Campaigns"));
const CreateCampaign = lazy(() => import("./pages/campaigns/CreateCampaign"));
const EditSubscriber = lazy(() => import("./pages/subscribers/EditSubscriber"));
const AgeSettings = lazy(() => import("./pages/settings/AgeSettings"));

// Website
const SitePositions = lazy(() => import("./pages/website/SitePositions"));
const AddNewSitePosition = lazy(() =>
  import("./pages/website/AddNewSitePosition")
);
const EditSitePosition = lazy(() => import("./pages/website/EditSitePosition"));

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/messages" element={<Messages />} />
          <Route
            path="/users"
            element={
              <Suspense fallback={<Loading />}>
                <Users />
              </Suspense>
            }
          />
          <Route
            path="/users/edit/:id"
            element={
              <Suspense fallback={<Loading />}>
                <FormEditUser />
              </Suspense>
            }
          />
          <Route
            path="/statistics"
            element={
              <Suspense fallback={<Loading />}>
                <Statistics />
              </Suspense>
            }
          />
          <Route
            path="/export"
            element={
              <Suspense fallback={<Loading />}>
                <ExportData />
              </Suspense>
            }
          />
          <Route
            path="/partners"
            element={
              <Suspense fallback={<Loading />}>
                <Partners />
              </Suspense>
            }
          />
          <Route
            path="/partners/edit/:id"
            element={
              <Suspense fallback={<Loading />}>
                <FormEditPartner />
              </Suspense>
            }
          />
          <Route
            path="/works"
            element={
              <Suspense fallback={<Loading />}>
                <Works />
              </Suspense>
            }
          />
          <Route
            path="/works/edit/:id"
            element={
              <Suspense fallback={<Loading />}>
                <FormEditWork />
              </Suspense>
            }
          />
          <Route
            path="/tasks"
            element={
              <Suspense fallback={<Loading />}>
                <Tasks />
              </Suspense>
            }
          />

          <Route
            path="/positions"
            element={
              <Suspense fallback={<Loading />}>
                <Positions />
              </Suspense>
            }
          />
          {/* ------ Employee ------ */}
          <Route
            path="/employees"
            element={
              <Suspense fallback={<Loading />}>
                <Employee />
              </Suspense>
            }
          />
          <Route
            path="/employees/edit/:id"
            element={
              <Suspense fallback={<Loading />}>
                <EditEmployee />
              </Suspense>
            }
          />
          {/* ----- Subscribers ----- */}
          <Route
            path="/subscribers"
            element={
              <Suspense fallback={<Loading />}>
                <Subscribers />
              </Suspense>
            }
          />
          <Route
            path="/subscribers/import"
            element={
              <Suspense fallback={<Loading />}>
                <AddNewSubscriber />
              </Suspense>
            }
          />
          <Route
            path="/subscribers/edit/:id"
            element={
              <Suspense fallback={<Loading />}>
                <EditSubscriber />
              </Suspense>
            }
          />
          {/* ----- Campaigns ----- */}
          <Route
            path="/campaigns"
            element={
              <Suspense fallback={<Loading />}>
                <Campaigns />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/create"
            element={
              <Suspense fallback={<Loading />}>
                <CreateCampaign />
              </Suspense>
            }
          />
          {/* ------ Website ------ */}
          <Route
            path="/website/positions"
            element={
              <Suspense fallback={<Loading />}>
                <SitePositions />
              </Suspense>
            }
          />
          <Route
            path="/website/positions/add-new"
            element={
              <Suspense fallback={<Loading />}>
                <AddNewSitePosition />
              </Suspense>
            }
          />
          <Route
            path="/website/positions/edit/:slug"
            element={
              <Suspense fallback={<Loading />}>
                <EditSitePosition />
              </Suspense>
            }
          />
          {/* ------ Settings ------ */}
          <Route
            path="/settings"
            element={
              <Suspense fallback={<Loading />}>
                <Settings />
              </Suspense>
            }
          />
          <Route
            path="/version"
            element={
              <Suspense fallback={<Loading />}>
                <Version />
              </Suspense>
            }
          />
          <Route
            path="/settings/mail"
            element={
              <Suspense fallback={<Loading />}>
                <Mail />
              </Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <Suspense fallback={<Loading />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path="/settings/logs"
            element={
              <Suspense fallback={<Loading />}>
                <Logs />
              </Suspense>
            }
          />
          <Route
            path="/settings/age-settings"
            element={
              <Suspense fallback={<Loading />}>
                <AgeSettings />
              </Suspense>
            }
          />
          <Route
            path="/settings/age-settings/edit/:id"
            element={
              <Suspense fallback={<Loading />}>
                <EditAgeSettings />
              </Suspense>
            }
          />
          <Route
            path="/settings/user-settings/edit/:id"
            element={
              <Suspense fallback={<Loading />}>
                <UserSettings />
              </Suspense>
            }
          />
          <Route
            path="/settings/mail"
            element={
              <Suspense fallback={<Loading />}>
                <Mail />
              </Suspense>
            }
          />
          <Route
            path="/settings/logs"
            element={
              <Suspense fallback={<Loading />}>
                <Logs />
              </Suspense>
            }
          />
          {/* ------ Login ------ */}
          {/* ------ If not found - Error Page ------ */}
          <Route path="*" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
