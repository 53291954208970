import React from "react";

const ModalHeaderTitle = ({ title, className = "" }) => {
  return (
    <h2
      className={`text-2xl text-center font-light tracking-wider ${className}`}>
      {title}
    </h2>
  );
};

export default ModalHeaderTitle;
