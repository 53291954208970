import React, { useState } from "react";

// Icon
import { IoIosLogOut } from "react-icons/io";

// Components
import AvatarMenu from "./AvatarMenu";
import MessageModal from "./MessageModal";
import LanguageSwitcher from "./LanguageSwitcher";
import LogoutModal from "../modal/logoutModal/LogoutModal";
import useDarkMode from "../darkMode/useDarkMode";

const Navbar = ({ handleClick }) => {
  /* ----- Dark Mode ----- */
  const [darkMode, toggleDarkMode] = useDarkMode();

  /* ---- Open Modal ---- */
  const [isLogOutModal, setIsLogOutModal] = useState(false);

  return (
    <section
      id="navbar-section"
      className="relative w-full h-12 flex justify-between items-center bg-white dark:bg-slate-900 rounded-3xl shadow-lg shadow-blue-400/20 py-3 px-4 md:px-8 mb-4">
      {/* ----- Left Side ----- */}
      {/* ----- Toggle button ----- */}
      <button
        aria-label="menu-button"
        onClick={handleClick}
        className="group w-fit h-fit flex flex-col justify-start items-start text-center rounded-full cursor-pointer gap-[6px] ">
        <span className="w-6 group-hover:w-6 h-[2px] bg-black dark:bg-white group-hover:bg-system-green dark:group-hover:bg-system-green border-none rounded-lg transition-all duration-200" />
        <span className="w-4 group-hover:w-6 h-[2px] bg-black dark:bg-white group-hover:bg-system-green dark:group-hover:bg-system-green border-none rounded-lg transition-all duration-200" />
        <span className="w-2 group-hover:w-6 h-[2px] bg-black dark:bg-white group-hover:bg-system-green dark:group-hover:bg-system-green border-none rounded-lg transition-all duration-200" />
      </button>
      {/* ----- Right Side ----- */}
      <div className="relative w-fit h-fit flex flex-row justify-center items-center gap-3">
        {/* ----- Dark mode button ----- */}{" "}
        <input
          type="checkbox"
          id="toggleBtn"
          onChange={() => toggleDarkMode(!darkMode)}
          checked={darkMode}
          className="appearance-none w-[38px] h-5 focus:outline-none bg-sky-300 rounded-full border border-gray-200 before:w-[18px] before:h-[18px] before:inline-block before:rounded-full before:bg-sun checked:before:translate-x-full checked:before:bg-moon checked:bg-slate-800 shadow-inner transition-all duration-300 before:shadow-sm before:shadow-slate-400 checked:before:shadow-slate-800  cursor-pointer"
        />
        {/* ----- Language ----- */}
        <LanguageSwitcher />
        {/* ----- Notifications ------ */}
        <MessageModal />
        {/* ----- Profile ------ */}
        <AvatarMenu />
        {/* ----- Log out ------ */}
        <button
          onClick={() => setIsLogOutModal(!isLogOutModal)}
          className="hidden md:flex justify-center items-center cursor-pointer bg-transparent border-none text-system-green dark:text-white text-xl hover:text-indigo-900 dark:hover:text-violet-700">
          <IoIosLogOut />
        </button>
      </div>
      {/* ----- Log out modal ------ */}
      <LogoutModal
        isLogOutModal={isLogOutModal}
        setIsLogOutModal={setIsLogOutModal}
      />
    </section>
  );
};

export default Navbar;
