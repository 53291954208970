import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Language
import { useTranslation } from "react-i18next";

// Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import MessagesItem from "./MessagesItem";

const MessagesForm = () => {
  /* ----- Language ----- */
  const { t } = useTranslation();

  /* ----- Privilege Admin & User ----- */
  const { user } = useSelector((state) => state.auth);

  /* ----- React App Api URL ----- */
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  /* ----- Messages ----- */
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [editMessageData, setEditMessageData] = useState(null);

  useEffect(() => {
    getMessages();
  }, []);

  const getMessages = async () => {
    const response = await axiosInstance.get("/messages");
    setMessages(response.data);
  };

  /* ----- Add Messages ----- */
  const addMessage = async (e) => {
    e.preventDefault();
    const messageData = {
      messageText: messageText,
    };
    // Only post if editMessageData is not provided
    if (!editMessageData) {
      await axiosInstance.post("/messages", messageData);
    } else {
      // Update the data if we do have editMessageData
      await axiosInstance.patch(
        `/messages/${editMessageData.uuid}`,
        messageData
      );
    }
    setMessageText("");
    getMessages(); // Live update
    setEditMessageData("");
  };

  /* ----- Edit Messages ----- */
  const editMessages = (messagesData) => {
    setEditMessageData(messagesData);
  };

  useEffect(() => {
    if (editMessageData) {
      setMessageText(
        editMessageData.messageText ? editMessageData.messageText : ""
      );
    }
  }, [editMessageData]);

  /* ----- DESC ----- */
  const renderMessages = () => {
    let sortedMessages = [...messages]; // időbeli megjelenités = a hozzáadás ideje alapján jelenjen meg, a backend adja vissza "timestamps"
    sortedMessages = sortedMessages.sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt); // Sort by DESC & Last edited first
    });

    return sortedMessages.map((message, i) => {
      return (
        <MessagesItem
          key={i}
          message={message}
          getMessages={getMessages}
          editMessages={editMessages}
        />
      );
    });
  };

  /* ----- Send Mail ----- */
  /* const [send, setSend] = useState(false);

  const sendEmail = async (e) => {
    setSend(true);
    try {
      await axiosInstance.post("/sendMsgMail", {
        messageText,
      });
    } catch (error) {
      console.log(error);
    }
  }; */

  /* ----- Add Message Box ----- */
  const insertMessages = () => {
    return (
      <>
        <ToastContainer />
        <form onSubmit={addMessage}>
          <h2 className="text-center text-black dark:text-white font-light text-3xl mb-4">
            {t("Messages.Messages")}
          </h2>
          {/* ----- Only Admin Start ----- */}
          {user && user.role === "admin" && (
            <div style={{ display: " flex", alignItems: "center" }}>
              <input
                type="text"
                placeholder={t("Messages.Enter_a_Messages")}
                className="w-full bg-white dark:bg-slate-800 border border-gray-200 rounded-lg text-black dark:text-white outline-none mr-2 p-2"
                value={messageText}
                required
                onChange={(e) => setMessageText(e.target.value)}
              />
              <button
                className="w-fit bg-transparent text-base text-[#25695C] rounded-lg border border-[#25695C] dark:border-white hover:bg-[#25695C] hover:text-white dark:text-white p-2 cursor-pointer"
                type="submit"
              >
                {editMessageData
                  ? `${t("Messages.Save")}`
                  : `${t("Messages.Add")}`}
              </button>
            </div>
          )}
          {/* ----- Only Admin End ----- */}
        </form>
      </>
    );
  };

  return (
    <div className="w-full box-border bg-white dark:bg-slate-900 rounded-lg shadow-lg shadow-blue-400/20 p-4 mb-4 ">
      {insertMessages()}
      {renderMessages()}
    </div>
  );
};

export default MessagesForm;
