import React from "react";

const Menubar = ({ children, className = "" }) => {
  return (
    <section
      id="menu-section"
      className={`w-full h-fit min-h-[3rem] flex justify-between items-center bg-white dark:bg-slate-900 rounded-3xl shadow-lg shadow-blue-400/20 py-2 px-4 md:px-8 ${className}`}>
      {children}
    </section>
  );
};

export default Menubar;
