import React, { useState } from "react";

// @mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  Typography,
} from "@mui/material";

// Components
import { AvatarColor, UserNameColor } from "../ui";

const DashboardEmployeeList = ({ t, user, employees }) => {
  /* ----- Table pagination ----- */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - employees.length) : 0;

  /* ----- Work ----- */
  function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  /* ----- Work Color ----- */
  function stringWork(work) {
    return {
      sx: {
        //bgcolor: stringToColor(work),
        borderLeft: "2px solid",
        borderColor: stringToColor(work),
        //borderRadius: "5px",
        padding: "0px 5px",
        alignItems: "left",
        display: "flex",
        justifyContent: "flex-start",
      },
    };
  }

  return (
    <div className="w-full h-fit bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-blue-400/20">
      <h2 className="text-black dark:text-white text-base md:text-2xl text-center md:text-left font-normal font-mont p-4">
        {t("EmployeeList.Last_Added")}
      </h2>
      <TableContainer className="dark:bg-slate-900 bg-white dark:text-white text-black">
        <Table aria-label="employee table">
          <TableHead size="small">
            <TableRow>
              <TableCell
                align="center"
                className="dark:text-white text-black"
                sx={{ height: "20px" }}>
                {t("EmployeeList.Travel_Date")}
              </TableCell>
              <TableCell
                className="dark:text-white text-black"
                sx={{ height: "20px" }}>
                {t("EmployeeList.Work")}
              </TableCell>
              <TableCell
                className="dark:text-white text-black"
                sx={{ height: "20px" }}>
                {t("EmployeeList.Positions")}
              </TableCell>
              <TableCell
                className="dark:text-white text-black"
                sx={{ height: "20px" }}>
                {t("EmployeeList.Name")}
              </TableCell>
              <TableCell
                className="dark:text-white text-black"
                sx={{ height: "20px" }}>
                {t("EmployeeList.Phone")}
              </TableCell>
              <TableCell align="center" className="dark:text-white text-black">
                {t("EmployeeList.Status")}
              </TableCell>
              {user && user.role === "admin" ? (
                <TableCell
                  className="dark:text-white text-black"
                  style={{
                    height: "20px",
                  }}>
                  {t("EmployeeList.Created")}
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {employees
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((employee) => (
                <TableRow key={employee.uuid} sx={{ maxHeight: 20 }}>
                  <TableCell
                    align="left"
                    className="dark:text-white text-black">
                    {employee.departure}
                  </TableCell>
                  <TableCell
                    className="dark:text-white text-black"
                    sx={{ width: "fit-content" }}>
                    {user && user.role === "admin" ? (
                      <Box {...stringWork(employee.work)}>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}>
                          {employee.work}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        style={{ fontSize: "14px", fontWeight: "400" }}>
                        {employee.work}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell className="dark:text-white text-black">
                    {employee.position}
                  </TableCell>
                  <TableCell className="dark:text-white text-black">
                    {employee.name}
                  </TableCell>
                  <TableCell className="dark:text-white text-black">
                    {employee.phoneNumber}
                  </TableCell>
                  <TableCell align="center" sx={{ width: "fit-content" }}>
                    <button
                      aria-label=" status button"
                      className={`w-full dark:text-white text-white rounded-md border-none px-3 ${
                        (employee.status === "Active" && "bg-system-green") ||
                        (employee.status === "Inactive" && "bg-orange-400") ||
                        (employee.status === "Pending" && "bg-sky-600")
                      } `}>
                      <Typography style={{ fontSize: "14px" }}>
                        {employee.status}
                      </Typography>
                    </button>
                  </TableCell>
                  {user && user.role === "admin" ? (
                    <TableCell
                      className="dark:text-white text-black"
                      sx={{ width: "fit-content" }}>
                      <UserNameColor
                        username={
                          employee.user.firstName + " " + employee.user.lastName
                        }
                      />
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          className="dark:text-white text-black"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={employees.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("EmployeeList.Rows_per_page")}
          showFirstButton={true}
          showLastButton={true}
        />
      </TableContainer>
    </div>
  );
};

export default DashboardEmployeeList;
