import React from "react";
import moment from "moment";

// Chart JS
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const StatCardMothly = ({ t, employees }) => {
  /* ----- Counter ----- */
  const currentMonth = moment().format("YYYY.MM"); // Current month
  const totalEmployees = employees.filter(
    (employee) => moment(employee.createdAt).format("YYYY.MM") === currentMonth
  );
  const totalCount = totalEmployees.length;
  // console.log(totalCountMan);

  /* ----- Monthly Counter ----- */
  const createdDate = employees
    .filter(
      (employee) =>
        moment(employee.createdAt).format("YYYY.MM") === currentMonth
    ) // Filter - Only Active employee
    .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)) // Sort by DESC and from the first day to the last day of the month
    .reduce((acc, employee) => {
      return {
        ...acc,
        [moment(employee.createdAt).format("YYYY.MM.DD")]:
          (acc[moment(employee.createdAt).format("YYYY.MM.DD")] || 0) + 1,
      };
    }, []);
  // console.log(createdDate);

  /* ----- Chart JS ----- */
  const data = {
    labels: [],
    datasets: [
      {
        fill: false, // Kitöltés
        label: "",
        data: createdDate,
        borderColor: "#fff",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        //pointStyle: "star",
        //pointBorderColor: "#fff",
        pointBorderWidth: 0,
        tension: 0.5,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    pointBackgroundColor: "#0396ff",
    radius: 0,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
    },
    scales: {
      x: {
        display: false,
        grid: { display: false },
        gridLines: {
          color: "rgba(235, 255, 76, 0.5)",
        },
        ticks: {
          display: false,
        },
      },
      y: {
        display: false,
        beginAtZero: true,
        ticks: {
          display: false,
          stepSize: 2, // Párosával mutassa
          //callback: (value) => value + "K",
        },
        grid: {
          //  borderDash: [10],// Szagatott vonal az Y
          display: false,
        },
      },
    },
  };

  return (
    <div className="w-full md:w-1/3 h-44 flex flex-col justify-center items-center bg-gradient-to-r from-[#abdcff] to-[#0396ff] text-center text-white tracking-wider rounded-md shadow-lg shadow-blue-400/20 p-4 gap-2">
      <h2 className="text-4xl lg:text-5xl xl:text-6xl font-normal drop-shadow-lg">
        {totalCount}
      </h2>
      <h3 className="text-xl font-normal tracking-wide drop-shadow-lg">
        {t("Statistic.Current_Month")}
      </h3>
      <div className="w-full pb-0 mb-0">
        <Line height={"50px"} width={"100%"} data={data} options={options} />
      </div>
    </div>
  );
};

export default StatCardMothly;
