import React from "react";

// @mui
import { Box, Typography } from "@mui/material";

const WorkColor = ({ workName }) => {
  /* ----- Color generator ----- */
  function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  /* ----- Work Color ----- */
  function stringWork(work) {
    return {
      sx: {
        //bgcolor: stringToColor(work),
        border: "2px solid",
        borderColor: stringToColor(work),
        borderRadius: "6px",
        padding: "0px 5px",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
    };
  }
  return (
    <Box {...stringWork(workName)}>
      <Typography
        sx={{
          fontSize: "14px",
        }}>
        {workName}
      </Typography>
    </Box>
  );
};

export default WorkColor;
