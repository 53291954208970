import React from "react";

// Layout
import Layout from "../../components/layout/Layout";

// Protected route
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import { useEffect } from "react";

// Components
import MessagesForm from "../../components/formMessages/MessagesForm";
import MessageCards from "../../components/formMessages/MessageCards";

const Messages = () => {
  /* ----- Privilege Admin & User ----- */
  const { user } = useSelector((state) => state.auth);

  /* ----- Protected route ----- */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  /* ----- Redirect ----- */
  useEffect(() => {
    if (isError) {
      navigate("/"); // Redirect to login screen
    }
  }, [isError, navigate]);

  return (
    <Layout>
      {/* ---- Admin ---- */}
      {user && user.role === "admin" && <MessagesForm />}
      {/* ---- User ---- */}
      {user && user.role === "user" && <MessageCards />}
    </Layout>
  );
};

export default Messages;
