import React, { Fragment, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

// Language
import { useTranslation } from "react-i18next";

// Logout
import { useSelector } from "react-redux";

// Assets
import Logo1 from "../../assets/img/DWH_Logo.png";

// Icons
import {
  AiOutlineHome,
  AiOutlineMenu,
  AiOutlineSetting,
  AiOutlinePieChart,
  AiOutlineUser,
} from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { IoIosLogOut } from "react-icons/io";
import { FaRegAddressCard } from "react-icons/fa";
import { BsJournalBookmark, BsBriefcase } from "react-icons/bs";
import { BiMessageDetail } from "react-icons/bi";
import { MdOutlineMapsHomeWork } from "react-icons/md";
import { PiCards } from "react-icons/pi";

// Components
import LogoutModal from "../modal/logoutModal/LogoutModal";
import SidebarTitle from "../ui/SidebarTitle";

const Sidebar = ({ handleClick }) => {
  /* ---- Privilege Admin & User ---- */
  const { user } = useSelector((state) => state.auth);

  /* ---- Open LogOut Modal ---- */
  const [isLogOutModal, setIsLogOutModal] = useState(false);

  /* ---- Aktiv Page ---- */
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  /* ---- Language ---- */
  const { t } = useTranslation();

  return (
    <section
      id="sidebar-section"
      className="relative w-72 h-screen flex flex-col justify-start items-start bg-white dark:bg-slate-900 shadow-lg shadow-blue-400/20 overflow-scroll no-scrollbar z-10">
      {/* ----- Menu button ----- */}
      <button
        aria-label="sidebar menu button"
        onClick={handleClick}
        className="absolute w-fit h-fit flex md:hidden items-center border-none bg-transparent text-black dark:text-white text-2xl hover:text-system-green cursor-pointer top-7 right-4">
        <AiOutlineMenu />
      </button>
      {/* ----- Company Logo ----- */}
      <div className="w-full h-fit flex justify-center items-center pt-14 mb-12">
        <img className="w-1/2 h-auto" src={Logo1} alt="Logo" />
      </div>
      {/* ----- Center Section ----- */}
      <section
        id="center-section"
        className="w-full h-fit flex flex-col text-black dark:text-white">
        <ul className="w-full h-fit flex flex-col justify-start items-start px-1 gap-1">
          <SidebarTitle title={t("Sidebar.General")} />
          <NavLink
            to={"/dashboard"}
            className="w-full h-fit flex justify-start items-center">
            <li
              className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                splitLocation[1] === "dashboard"
                  ? "bg-system-green text-white"
                  : "hover:bg-system-green hover:text-white"
              }`}>
              <AiOutlineHome className="text-[1.4rem] ml-5 " />
              <span className="text-left text-base font-normal tracking-wider">
                {t("Sidebar.Dashboard")}
              </span>
            </li>
          </NavLink>
          <NavLink
            to={"/messages"}
            className="w-full h-fit flex justify-start items-center">
            <li
              className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                splitLocation[1] === "messages"
                  ? "bg-system-green text-white"
                  : "hover:bg-system-green hover:text-white"
              }`}>
              <BiMessageDetail className="text-[1.4rem] ml-5" />
              <span className="text-left text-base font-normal tracking-wider">
                {t("Sidebar.Messages")}
              </span>
            </li>
          </NavLink>
          {/*  */}
          <SidebarTitle title={t("Sidebar.Employees")} />
          <NavLink
            to={"/employees"}
            className="w-full h-fit flex justify-start items-center">
            <li
              className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                splitLocation[1] === "employees"
                  ? "bg-system-green text-white"
                  : "hover:bg-system-green hover:text-white"
              }`}>
              <FaRegAddressCard className="text-[1.4rem] ml-5" />
              <span className="text-left text-base font-normal tracking-wider">
                {t("Sidebar.Employees")}
              </span>
            </li>
          </NavLink>
          <NavLink
            to={"/statistics"}
            className="w-full h-fit flex justify-start items-center">
            <li
              className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                splitLocation[1] === "statistics"
                  ? "bg-system-green text-white"
                  : "hover:bg-system-green hover:text-white"
              }`}>
              <AiOutlinePieChart className="text-[1.4rem] ml-5" />
              <span className="text-left text-base font-normal tracking-wider">
                {t("Sidebar.Statistics")}
              </span>
            </li>
          </NavLink>
          {/*  {user && user.exportDataMenu === "yes" ? (
            <NavLink
              to={"/export"}
              className="w-full h-fit flex justify-start items-center">
              <li
                className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                  splitLocation[1] === "export"
                    ? "bg-system-green text-white"
                    : "hover:bg-system-green hover:text-white"
                }`}>
                <AiOutlineCloudDownload className="text-[1.4rem] ml-5" />
                <span className="text-left text-base font-normal tracking-wider ">
                  {t("Sidebar.Export_Data")}
                </span>
              </li>
            </NavLink>
          ) : null} */}
          {/* ----- Only Admin Start ----- */}
          {user && user.role === "admin" ? (
            <NavLink
              to={"/subscribers"}
              className="w-full h-fit flex justify-start items-center">
              <li
                className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                  splitLocation[1] === "subscribers"
                    ? "bg-system-green text-white"
                    : "hover:bg-system-green hover:text-white"
                }`}>
                <AiOutlineUser className="text-[1.4rem] ml-5" />
                <span className="text-left text-base font-normal tracking-wider">
                  {t("Sidebar.Subscribers")}
                </span>
              </li>
            </NavLink>
          ) : null}
          {/*  */}
          {user && user.role === "admin" ? (
            <Fragment>
              <SidebarTitle title={t("Sidebar.Admin")} />
              <NavLink
                to={"/users"}
                className="w-full h-fit flex justify-start items-center">
                <li
                  className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                    splitLocation[1] === "users"
                      ? "bg-system-green text-white"
                      : "hover:bg-system-green hover:text-white"
                  }`}>
                  <FiUsers className="text-[1.4rem] ml-5" />
                  <span className="text-left text-base font-normal tracking-wider">
                    {t("Sidebar.Users")}
                  </span>
                </li>
              </NavLink>

              <NavLink
                to={"/partners"}
                className="w-full h-fit flex justify-start items-center">
                <li
                  className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                    splitLocation[1] === "partners"
                      ? "bg-system-green text-white"
                      : "hover:bg-system-green hover:text-white"
                  }`}>
                  <BsJournalBookmark className="text-[1.4rem] ml-5" />
                  <span className="text-left text-base font-normal tracking-wider">
                    {t("Sidebar.Partners")}
                  </span>
                </li>
              </NavLink>
              <NavLink
                to={"/works"}
                className="w-full h-fit flex justify-start items-center">
                <li
                  className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                    splitLocation[1] === "works"
                      ? "bg-system-green text-white"
                      : "hover:bg-system-green hover:text-white"
                  }`}>
                  <MdOutlineMapsHomeWork className="text-[1.4rem] ml-5" />
                  <span className="text-left text-base font-normal tracking-wider">
                    {t("Sidebar.Works")}
                  </span>
                </li>
              </NavLink>
              <NavLink
                to={"/positions"}
                className="w-full h-fit flex justify-start items-center">
                <li
                  className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                    splitLocation[1] === "positions"
                      ? "bg-system-green text-white"
                      : "hover:bg-system-green hover:text-white"
                  }`}>
                  <BsBriefcase className="text-[1.4rem] ml-5" />
                  <span className="text-left text-base font-normal tracking-wider">
                    {t("Sidebar.Positions")}
                  </span>
                </li>
              </NavLink>
              {/*  <NavLink to={"/campaigns"}   
              className="w-full h-fit flex justify-start items-center">
                   <li
                    className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                      splitLocation[1] === "campaigns"
                        ? "bg-system-green text-white"
                        : "hover:bg-system-green hover:text-white"
                    }`}>
                    <BsEnvelope className="text-[1.4rem] ml-5" />
                    <span className="text-left text-base font-normal tracking-wider">
                      {t("Campaigns.Campaigns")}
                    </span>
                  </li>
                </NavLink> */}
            </Fragment>
          ) : null}
          {/*  */}
          {/* ----- Only Admin ----- */}
          {user && user.role === "admin" ? (
            <Fragment>
              <SidebarTitle title={t("Sidebar.Website")} />
              <NavLink
                to={"/website/positions"}
                className="w-full h-fit flex justify-start items-center">
                <li
                  className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                    pathname.match(/^\/website/)
                      ? "bg-system-green text-white"
                      : "hover:bg-system-green hover:text-white"
                  }`}>
                  <PiCards className="text-[1.4rem] ml-5" />
                  <span className="text-left text-base font-normal tracking-wider">
                    {t("Sidebar.SitePositions")}
                  </span>
                </li>
              </NavLink>
            </Fragment>
          ) : null}
          {/* ----- Only Admin ----- */}
          {user && user.role === "admin" ? (
            <Fragment>
              <SidebarTitle title={t("Sidebar.Settings")} />
              <NavLink
                to={"/settings"}
                className="w-full h-fit flex justify-start items-center">
                <li
                  className={`w-full h-10 flex flex-row justify-start items-center rounded-md gap-3 ${
                    splitLocation[1] === "settings"
                      ? "bg-system-green text-white"
                      : "hover:bg-system-green hover:text-white"
                  }`}>
                  <AiOutlineSetting className="text-[1.4rem] ml-5" />
                  <span className="text-left text-base font-normal tracking-wider">
                    {t("Sidebar.Settings")}
                  </span>
                </li>
              </NavLink>
            </Fragment>
          ) : null}
          <li
            onClick={() => setIsLogOutModal(!isLogOutModal)}
            className="w-full h-10 flex flex-row justify-start items-center rounded-md hover:bg-system-green hover:rounded-md hover:text-white gap-3">
            <IoIosLogOut className="text-[1.4rem] ml-5" />
            <span className="text-left text-base font-normal tracking-wider">
              {t("Sidebar.Logout")}
            </span>
          </li>
        </ul>
      </section>
      {/* ----- Developed By ----- */}
      <div className="w-full h-fit flex flex-col justify-center items-center text-center text-black dark:text-white text-xs cursor-pointer mt-4 left-1/2 ">
        <a href="https://smartlife2.com">Developed by Smart life 2.0 </a>
        <NavLink to={"/version"}>
          <span className="text-xs">Version 3.0.3 </span>
        </NavLink>
      </div>
      {/* ----- Logout Modal ----- */}
      <LogoutModal
        isLogOutModal={isLogOutModal}
        setIsLogOutModal={setIsLogOutModal}
      />
    </section>
  );
};

export default Sidebar;
