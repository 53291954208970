import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// Language
import { useTranslation } from "react-i18next";

// Icon
import { AiOutlineSetting } from "react-icons/ai";
import { IoIosLogOut } from "react-icons/io";

// Components
import AvatarColor from "../ui/AvatarColor";
import LogoutModal from "../modal/logoutModal/LogoutModal";

const AvatarMenu = () => {
  /* ----- Language ----- */
  const { t } = useTranslation();

  /* ----- Privilege Admin & User ----- */
  const { user } = useSelector((state) => state.auth);

  /* ----- Toggle ----- */
  const [showMenu, setShowMenu] = useState(false);

  /* ---- Open Modal ---- */
  const [isLogOutModal, setIsLogOutModal] = useState(false);

  /* ----- Click Outside to Close ----- */
  const menuRef = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    };
  });

  return (
    <section
      id="avatar-section"
      className="relative w-fit h-fit flex flex-row justify-center items-center">
      <button
        ref={menuRef}
        aria-label="avatar menu button"
        onClick={() => setShowMenu(!showMenu)}
        className="w-full hidden md:flex flex-row justify-center items-center bg-transparent hover:bg-system-green border border-system-green dark:border-white text-black dark:text-white hover:text-white text-center text-base font-normal shadow-lg shadow-blue-400/20   rounded-md cursor-pointer py-0.5 px-1 gap-1">
        {user && user.firstName} {user && user.lastName}
      </button>
      <div
        ref={menuRef}
        onClick={() => setShowMenu(!showMenu)}
        className="w-fit h-fit flex md:hidden justify-center items-center">
        <AvatarColor
          username={
            `${user && user.firstName}` + " " + `${user && user.lastName}`
          }
        />
      </div>
      {/* ----- Profile Menu ----- */}
      {showMenu ? (
        <div
          ref={menuRef}
          className="absolute flex flex-col justify-center items-center cursor-pointer bg-white dark:bg-slate-900 border-none shadow-lg shadow-blue-400/20 text-black dark:text-white  rounded-md top-10 right-0 p-2 z-10">
          <Link
            to="/profile"
            className="w-full flex justify-start items-center bg-transparent border border-transparent hover:border-system-green dark:hover:border-gray-200 rounded-md hover:shadow-lg hover:shadow-blue-400/20 decoration-none px-4 py-1 gap-2">
            <AvatarColor
              username={
                `${user && user.firstName}` + " " + `${user && user.lastName}`
              }
            />
            {t("Sidebar.Profile")}
          </Link>
          <hr className="w-11/12 h-px bg-gray-200 border-none rounded-sm m-2" />
          <div>
            {/* ----- Only Admin Start ----- */}
            {user && user.role === "admin" && (
              <Link
                to="/settings"
                className="flex justify-start items-center bg-transparent border border-transparent hover:border-system-green dark:hover:border-gray-200 text-lg rounded-md hover:shadow-lg hover:shadow-blue-400/20 p-2 gap-1 cursor-pointer">
                <AiOutlineSetting />
                {t("Sidebar.Settings")}
              </Link>
            )}
            {/* ----- LogOut ----- */}
            <button
              onClick={() => setIsLogOutModal(!isLogOutModal)}
              style={{ border: "none", background: "none", width: "100%" }}>
              <div className="flex justify-start items-center p-2 gap-1 cursor-pointer text-lg text-black dark:text-white bg-transparent border border-transparent hover:border-system-green dark:hover:border-gray-200 rounded-md hover:shadow-lg hover:shadow-blue-400/20">
                <IoIosLogOut />
                {t("Sidebar.Logout")}
              </div>
            </button>
          </div>
        </div>
      ) : null}
      {/* ----- LogOut Modal ----- */}
      <LogoutModal
        isLogOutModal={isLogOutModal}
        setIsLogOutModal={setIsLogOutModal}
      />
    </section>
  );
};

export default AvatarMenu;
