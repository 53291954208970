import React from "react";

const SidebarTitle = ({ title, className = "" }) => {
  return (
    <h3
      className={`w-11/12 border-b border-gray-200 dark:border-gray-600 text-system-green text-left text-xl font-normal tracking-wider ml-2 mb-1 ${className}`}>
      {title}
    </h3>
  );
};

export default SidebarTitle;
