import React, { Fragment } from "react";

// @mui
import { Avatar } from "@mui/material";

const AvatarColor = ({ username }) => {
  /* ----- Color generator ----- */
  function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  /* ----- Avatar Color ----- */
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 30,
        height: 30,
        fontSize: 14,
        align: "center",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <Fragment>
      <Avatar align="center" {...stringAvatar(username)} />
    </Fragment>
  );
};

export default AvatarColor;
