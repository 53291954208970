import React, { useEffect, useRef, useState } from "react";

// Language
import { useTranslation } from "react-i18next";

// Image
import ENG from "../../assets/icon/gb.png";
import HUN from "../../assets/icon/hu.png";
import UAH from "../../assets/icon/ua.png";
import ENG1 from "../../assets/icon/gb1.png";
import HUN1 from "../../assets/icon/hu1.png";
import UAH1 from "../../assets/icon/ua1.png";

const LanguageSwitcher = () => {
  /* ----- Language ----- */
  const { i18n } = useTranslation();

  /* ---- Show selected lang Icon ----- */
  const getLanguage = () => {
    return (
      i18n.language ||
      (window !== "undefined" && window.localStorage.i18nextLng) ||
      "en"
    );
  };

  /* ----- Toggle ----- */
  const [showLangMenu, setShowLangMenu] = useState(false);

  /* ----- Click Outside to Close ----- */
  const menuRef = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setShowLangMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    };
  });

  return (
    <section id="language-switcher-section" className="relative">
      {/* ----- Icon & Show & Close Button ----- */}
      <button
        className="flex justify-center items-center rounded-full border border-transparent hover:border-system-green cursor-pointer"
        onClick={() => setShowLangMenu(!showLangMenu)}
        ref={menuRef}>
        {getLanguage() === "en" && (
          <img src={ENG} alt="language" className="w-5 h-5" />
        )}
        {getLanguage() === "hu" && (
          <img src={HUN} alt="language" className="w-5 h-5" />
        )}
        {getLanguage() === "ua" && (
          <img src={UAH} alt="language" className="w-5 h-5" />
        )}
      </button>
      {/* ----- Lang Menu ----- */}
      {showLangMenu ? (
        <div
          onClick={() => setShowLangMenu(!showLangMenu)}
          ref={menuRef}
          className="absolute w-fit h-fit flex flex-col justify-center items-center cursor-pointer bg-white dark:bg-slate-900 border-none text-system-green dark:text-white rounded-md shadow-lg shadow-blue-400/20 top-9 -right-7 p-2 z-10">
          {/* <span className="absolute -top-[7px] right-10 w-4 h-4 before:w-4 bg-white dark:bg-slate-900 origin-center rotate-45 -z-10"></span> */}
          <button
            className="flex justify-center items-center bg-transparent border border-transparent text-base px-4 hover:bg-gray-200 dark:hover:bg-transparent dark:hover:border dark:hover:border-white rounded-md cursor-pointer "
            onClick={() => i18n.changeLanguage("en")}>
            <img src={ENG1} alt="language" className="w-5 mr-1" />
            <span className="font-normal text-base">EN</span>
          </button>
          <button
            className="flex justify-center items-center bg-transparent border border-transparent text-base px-4 hover:bg-gray-200 dark:hover:bg-transparent dark:hover:border dark:hover:border-white rounded-md cursor-pointer "
            onClick={() => i18n.changeLanguage("hu")}>
            <img src={HUN1} alt="language" className=" w-5 mr-1" />
            <span className="font-normal text-base">HU</span>
          </button>
          <button
            className="flex justify-center items-center bg-transparent border border-transparent text-base px-4 hover:bg-gray-200 dark:hover:bg-transparent dark:hover:border dark:hover:border-white rounded-md cursor-pointer "
            onClick={() => i18n.changeLanguage("ua")}>
            <img src={UAH1} alt="language" className="w-5 mr-1" />
            {/* &nbsp; */}
            <span className="font-normal text-base">UA</span>
          </button>
        </div>
      ) : null}
    </section>
  );
};

export default LanguageSwitcher;
