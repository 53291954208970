import React, { useEffect, useRef, useState } from "react";

// Language
import { t } from "i18next";

// Icon
import { IoMdNotificationsOutline } from "react-icons/io";

const MessageModal = () => {
  /* ----- Toggle ----- */
  const [showModal, setShowModal] = useState(false);

  /* ----- Click Outside to Close ----- */
  const menuRef = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    };
  });

  return (
    <section id="message-section" className="relative">
      {/* ----- Icon & Show  & Close Button ----- */}
      <button
        onClick={() => setShowModal(!showModal)}
        className="flex justify-center items-center bg-transparent border-none text-system-green dark:text-white text-xl hover:text-indigo-900 dark:hover:text-violet-700 cursor-pointer">
        <IoMdNotificationsOutline />
      </button>
      {/* ----- Msg Container ----- */}
      {showModal ? (
        <div
          onClick={() => setShowModal(!showModal)}
          ref={menuRef}
          className="absolute w-24 h-fit flex flex-col justify-center items-center cursor-pointer bg-white dark:bg-slate-900 border-none  shadow-lg shadow-blue-400/20 rounded-md top-9 -right-7 p-2 z-10 ">
          <p className="text-black dark:text-white text-center text-base font-normal tracking-wider">
            {t("Messages.No_new_message")}
          </p>
        </div>
      ) : null}
    </section>
  );
};

export default MessageModal;
