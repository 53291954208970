import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";

// Language
import { useTranslation } from "react-i18next";

// Style
import "../../styles/Checkmark.css";

// Icons
import { FiEdit3 } from "react-icons/fi";
import { FcFullTrash } from "react-icons/fc";

// Components
import ModalLayout from "../layout/ModalLayout";

const MessagesItem = ({ message, getMessages, editMessages }) => {
  /* ----- Language ----- */
  const { t } = useTranslation();

  /* ----- Privilege Admin & User ----- */
  const { user } = useSelector((state) => state.auth);

  /* ----- React App Api URL ----- */
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  /* ----- Delete Messages ----- */
  // No popup, just the button
  /*   const deleteMessages = async () => {
    if (window.confirm(`${t("Messages.Delete")}`)) {
      await axiosInstance.delete(`/messages/${message.uuid}`);
      getMessages(); // Live update
    }
  }; */

  /* ----- Open delete modal ----- */
  const [popup, setPopup] = useState(false);
  const toggleModal = () => {
    setPopup(false);
  };

  /* ----- Delete confirmation dialog with popup ----- */
  const [deleteId, setDeleteId] = useState("");
  const deleteMessages = (employeeId) => {
    setDeleteId(employeeId);
    setPopup(true);
  };

  /* ----- Delete Animation ----- */
  const [state, setState] = useState(`${t("Modal.Delete")}`);

  const handleDeleteItem = async () => {
    setState(`${t("Modal.Loading")}`); // Delete Animation
    await axiosInstance.delete(`/messages/${message.uuid}`);
    getMessages(); // Live update
    // Deleted Animation
    setTimeout(() => {
      setState(`${t("Modal.Deleted")}`);
    }, 500);
    // Close popup after 5s
    setTimeout(() => {
      setPopup(false);
    }, 5000);
    //
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center bg-transparent border border-gray-200 rounded-lg mt-4 p-2 gap-2 md:gap-0">
        <div className="flex flex-col md:flex-row justify-center items-center  break-all text-black dark:text-white pl-0 md:pl-4 gap-2 md:gap-4">
          <h3 className="font-light text-lg">
            {moment(message.updatedAt, "YYYY-MM-DD hh:mm:ss").format(
              // Date format & split date
              "YYYY.MM.DD:"
            )}
          </h3>
          <h3 className="font-light text-lg text-center">
            {message.messageText}
          </h3>
        </div>
        <div className="flex justify-center items-center gap-2 pr-4">
          <button
            className="text-orange-400 text-lg bg-transparent border-none cursor-pointer"
            onClick={() => editMessages(message)}>
            <FiEdit3 />
          </button>
          {/* ----- Only Admin Start ----- */}
          {user && user.role === "admin" && (
            <button
              className="text-lg bg-transparent border-none cursor-pointer"
              onClick={() => deleteMessages(message.uuid)}>
              <FcFullTrash />
            </button>
          )}
        </div>
        {/* ----- Only Admin End ----- */}
      </div>
      {/* ----- Delete modal ----- */}
      <ModalLayout trigger={popup} setTrigger={setPopup}>
        <div>
          {state === `${t("Modal.Delete")}` ? (
            <div className="flex flex-col justify-center items-center text-black dark:text-white text-center">
              <h2 className="text-2xl font-light">{t("Modal.Delete_it")} </h2>
              <h2 className="text-2xl font-light">{message.messageText} </h2>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <div className="success-checkmark">
                <div className="check-icon">
                  <span className="icon-line line-tip"></span>
                  <span className="icon-line line-long"></span>
                  <div className="icon-circle"></div>
                  <div className="icon-fix"></div>
                </div>
              </div>
            </div>
          )}
          <div className="w-full flex justify-between items-center mt-4 gap-8 text-white">
            {state === `${t("Modal.Deleted")}` ? null : (
              <button
                onClick={handleDeleteItem}
                className="w-full h-8 rounded-3xl text-base font-normal cursor-pointer bg-red-900 border hover:border-red-600 hover:bg-white hover:text-red-900">
                {state}
              </button>
            )}
            {/* <button
                className="w-full h-8 rounded-3xl text-base font-normal cursor-pointer bg-red-900 border hover:border-red-600 hover:bg-white hover:text-red-900"
                onClick={toggleModal}>
                 After click, change button function 
                {state}11
              </button> */}
            <button
              className="w-full h-8 rounded-3xl text-base font-normal cursor-pointer  bg-system-blue border hover:border-system-blue hover:bg-white hover:text-system-blue"
              onClick={toggleModal}>
              {t("Modal.Close")}
            </button>
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

export default MessagesItem;
