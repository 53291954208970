import React, { useEffect, useRef } from "react";

// Animation
import Lottie from "lottie-react";
import Check from "../../../assets/json/check.json";

const SuccessModal = ({ showSuccessModal, setShowSuccessModal }) => {
  /* ----- Click Outside to Close ----- */
  const menuRef = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setShowSuccessModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    };
  });

  return showSuccessModal ? (
    <section
      id="modal-section"
      className="fixed w-full h-screen flex justify-center items-center backdrop-blur-sm bg-black/60 inset-0 z-10">
      <div className="w-full h-fit flex justify-center items-center ">
        <Lottie animationData={Check} loop={false} className="w-1/3" />
      </div>
    </section>
  ) : null;
};

export default SuccessModal;
