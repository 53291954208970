import React, { useEffect, useState } from "react";
import moment from "moment";

const InfoCard = ({t, user }) => {
/* ----- Clock & Date ----- */
  const [clockState, setClockState] = useState();

  useEffect(() => {
    setInterval(() => {
      const date = new Date();
      setClockState(date.toLocaleTimeString());
    }, 100);
  });

  return (
    <div className="relative w-full md:w-1/3 h-44 flex flex-col justify-center items-center md:items-start bg-system-green border-none text-center md:text-left tracking-wider rounded-md shadow-lg shadow-blue-400/20 overflow-hidden p-4">
      <hr className="absolute w-36 h-0.5 bg-white border-none rounded-md rotate-90 top-1/2 -translate-y-1/2 -left-16" />
      <h1 className="text-4xl xl:text-5xl text-white font-normal mb-2">
        {clockState}
      </h1>
      <p className="text-xl text-white font-normal mb-4">
        {moment().format("LL")}
      </p>
      <h2 className="text-3xl text-white font-light tracking-wide">
        {t("Welcome")} {user && user.lastName}
      </h2>
    </div>
  );
};

export default InfoCard;
