import React, { useState } from "react";

// Hooks
import { useMediaQuery } from "@mui/material";

// Protected route
import { useSelector } from "react-redux";

// Components
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import PlusAddNew from "../modal/employee/addNew/PlusAddNew";
import Loading from "../loading/Loading";

const Layout = ({ children }) => {
  /* ----- Protected route ----- */
  const { user } = useSelector((state) => state.auth);

  /* ----- Desktop and Mobile view ----- */
  const matches = useMediaQuery("(min-width:768px)");

  /* ----- Sidebar show & hide ----- */
  const [isOpen, setOpen] = useState(matches);

  const handleClick = () => {
    setOpen(!isOpen);
  };
  if (user === null) {
    <Loading />;
  } else {
    return (
      <div className="w-full flex justify-between">
        {isOpen ? <Sidebar handleClick={handleClick} /> : null}
        <main className="absolute md:relative w-full h-screen bg-slate-100 dark:bg-slate-800 overflow-y-scroll p-4">
          <Navbar handleClick={handleClick} />
          {children} <PlusAddNew />
        </main>
      </div>
    );
  }
};

export default Layout;
