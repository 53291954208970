import React from "react";
import { useNavigate } from "react-router-dom";
import { LogOut, reset } from "../../../features/authSlice";

// Language
import { useTranslation } from "react-i18next";

// Redux
import { useDispatch } from "react-redux";

// Components
import ModalLayout from "../../layout/ModalLayout";

const LogoutModal = ({ isLogOutModal, setIsLogOutModal }) => {
  /* ----- Language ----- */
  const { t } = useTranslation();

  /* ----- Logout ----- */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };

  return (
    <ModalLayout trigger={isLogOutModal} setTrigger={setIsLogOutModal}>
      <div className="w-full h-fit flex flex-col justify-center items-center text-center text-black dark:text-white gap-8">
        <h2 className="text-2xl md:text-3xl font-light">
          {t("Navbar.Logout")}
        </h2>
        <div className="w-full flex justify-between items-center gap-4">
          <button
            onClick={logout}
            className="w-full h-fit bg-system-green hover:bg-transparent border border-transparent hover:border-system-green text-white hover:text-black dark:hover:text-white text-base md:text-xl font-normal tracking-wider rounded-lg px-4 py-1">
            {t("Navbar.Yes")}
          </button>
          <button
            onClick={() => setIsLogOutModal(!isLogOutModal)}
            className="w-full h-fit bg-system-blue hover:bg-transparent border border-transparent hover:border-system-blue text-white hover:text-black dark:hover:text-white text-base md:text-xl font-normal tracking-wider rounded-lg px-4 py-1">
            {t("Navbar.No")}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default LogoutModal;
