import React from "react";

const UserNameColor = ({ username }) => {
  /* ----- Color generator ----- */
  function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  return (
    <div
      style={{ backgroundColor: stringToColor(username), fontSize: "14px" }}
      className="w-[6rem] h-fit flex flex-row justify-center items-center text-center text-white rounded-md break-keep px-0 py-[3px] ">
      {`${username.split(" ")[0][0]}` +
        "." +
        " " +
        `${
          username.split(" ")[1].length > 7
            ? username.split(" ")[1].slice(0, 7) + "..."
            : username.split(" ")[1]
        }`}
    </div>
  );
};

export default UserNameColor;
