// Styles
import "../../styles/loader.css";

const Loading = () => {
  return (
    <div className="load">
      <hr />
      <hr />
      <hr />
      <hr />
    </div>
  );
};

export default Loading;
