import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

// Language
import { useTranslation } from "react-i18next";

const MessageCards = () => {
  /* ----- Language ----- */
  const { t } = useTranslation();

  /* ----- React App Api URL ----- */
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  /* ----- Messages ----- */
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    getMessages();
  }, []);

  const getMessages = async () => {
    const response = await axiosInstance.get("/messages");
    setMessages(response.data);
  };

  return (
    <div className="bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-blue-400/20 p-4">
      <h2 className="text-center text-2xl font-normal font-mont text-system-green">
        {t("Messages.Messages")}
      </h2>
      <div className="flex flex-wrap justify-center items-center mt-4 gap-4">
        {messages
          .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1)) // Sort by DESC & Last edited first
          .map((message, index) => (
            <div
              key={index}
              className="w-64 h-64 bg-[#f4f39e] border-none rounded-md break-all text-center leading-7 shadow-lg shadow-blue-400/20 overflow-y-scroll p-4 "
            >
              <div className="border-b border-gray-300 mb-4">
                <p className="text-base font-normal text-center">
                  {moment(message.updatedAt, "YYYY-MM-DD hh:mm:ss").format(
                    // Date format & split date
                    "YYYY.MM.DD"
                  )}
                </p>
              </div>
              <div>
                <p className="text-xl font-normal text-center">
                  {message.messageText}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MessageCards;
