import React, { useEffect, useState } from "react";
import axios from "axios";
//import { useNavigate } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// Language
import { useTranslation } from "react-i18next";

// Alert
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import { FormButtons, Inputs, ModalTitle, SelectLabel } from "../../../ui";

const EmployeeForm = ({
  open,
  setOpen,
  showSuccessModal,
  setShowSuccessModal,
}) => {
  /* ----- Language ----- */
  const { t } = useTranslation();

  /* ----- Privilege Admin & User ----- */
  const { user } = useSelector((state) => state.auth);

  /* ----- React App Api URL ----- */
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  /* ----- Data ----- */
  const [position, setPosition] = useState("");
  const [work, setWork] = useState("");
  const [departure, setDeparture] = useState("");
  const [startingPoint, setStartingPoint] = useState("");
  const [name, setName] = useState("");
  const [vat, setVat] = useState("");
  const [taj, setTaj] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [nameOfMom, setNameOfMom] = useState("");
  const [nameAtBirth, setNameAtBirth] = useState("");
  const [cityOfBirth, setCityOfBirth] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [idCardNumber, setIdCardNumber] = useState("");
  const [biometricDays, setBiometricDays] = useState("");
  const [nationality, setNationality] = useState("");
  const [index, setIndex] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [itsPublic, setItsPublic] = useState("");
  const [numberOfHouse, setNumberOfHouse] = useState("");
  const [floor, setFloor] = useState("");
  const [door, setDoor] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [etc1, setEtc1] = useState("");
  const [etc2, setEtc2] = useState("");
  const [etc3, setEtc3] = useState("");
  const [etc4, setEtc4] = useState("");
  const [etc4Shoe, setEtc4Shoe] = useState("");
  const [etc5, setEtc5] = useState("");
  const [professional, setProfessional] = useState("");
  const [previousWorkplace, setPreviousWorkplace] = useState("");
  const [etc6, setEtc6] = useState("");
  const [status, setStatus] = useState("");
  const [accommodation, setAccommodation] = useState("");
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);
  //const [msg, setMsg] = useState("");

  const saveEmployee = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/employees", {
        position: position,
        work: work,
        departure: departure,
        startingPoint: startingPoint,
        name: name,
        vat: vat,
        taj: taj,
        idNumber: idNumber,
        vatNumber: vatNumber,
        nameOfMom: nameOfMom,
        nameAtBirth: nameAtBirth,
        cityOfBirth: cityOfBirth,
        dateOfBirth: dateOfBirth,
        gender: gender,
        idCardNumber: idCardNumber,
        biometricDays: biometricDays,
        nationality: nationality,
        index: index,
        city: city,
        street: street,
        itsPublic: itsPublic,
        numberOfHouse: numberOfHouse,
        floor: floor,
        door: door,
        phoneNumber: phoneNumber,
        email: email,
        etc1: etc1,
        etc2: etc2,
        etc3: etc3,
        etc4: etc4,
        etc4Shoe: etc4Shoe,
        etc5: etc5,
        professional: professional,
        previousWorkplace: previousWorkplace,
        etc6: etc6,
        status: status,
        accommodation: accommodation,
        comment: comment,
      });
      // Close modal after submit
      setOpen(!open);
      // Show success modal
      setShowSuccessModal(true);
      // Close modal after submit
      setTimeout(() => {
        setShowSuccessModal(false);
        // Reload page after submit
        // window.location.reload(false);
      }, 2000);
      // Send Email after submit
      sendEmail();
    } catch (error) {
      // Message from the backend
      /* if (error.response) {
        setMsg(error.response.data.msg);
      } */
      if (
        // Missing data & Error
        position.length === 0 ||
        work.length === 0 ||
        departure.length === 0 ||
        startingPoint.length === 0 ||
        name.length === 0 ||
        vat.length === 0 ||
        taj.length === 0 ||
        idNumber.length === 0 ||
        vatNumber.length === 0 ||
        nameOfMom.length === 0 ||
        nameAtBirth.length === 0 ||
        cityOfBirth.length === 0 ||
        dateOfBirth.length === 0 ||
        gender.length === 0 ||
        idCardNumber.length === 0 ||
        biometricDays.length === 0 ||
        nationality.length === 0 ||
        index.length === 0 ||
        city.length === 0 ||
        street.length === 0 ||
        itsPublic.length === 0 ||
        numberOfHouse.length === 0 ||
        floor.length === 0 ||
        door.length === 0 ||
        phoneNumber.length === 0 ||
        email.length === 0 ||
        etc1.length === 0 ||
        etc2.length === 0 ||
        etc3.length === 0 ||
        etc4.length === 0 ||
        etc4Shoe.length === 0 ||
        etc5.length === 0 ||
        etc6.length === 0 ||
        status.length === 0 ||
        accommodation.length === 0 ||
        comment.length === 0
      ) {
        setError(true);
      }
    }
  };

  /* ----- Alert ----- */
  const handleErrors = () => {
    toast.error(work.length <= 0 && `${t("Errors.Error_Work")}`);
    toast.error(position.length <= 0 && `${t("Errors.Error_Positions")}`);
    toast.error(departure.length <= 0 && `${t("Errors.Error_Travel_Date")}`);
    toast.error(startingPoint.length <= 0 && `${t("Errors.Error_Country")}`);
    toast.error(name.length <= 0 && `${t("Errors.Error_Name")}`);
    toast.error(vat.length <= 0 && `${t("Errors.Error_TAX")}`);
    toast.error(taj.length <= 0 && `${t("Errors.Error_TAJ")}`);
    toast.error(idNumber.length <= 0 && `${t("Errors.Missing_data")}`);
    toast.error(vatNumber.length <= 0 && `${t("Errors.Missing_data")}`);
    toast.error(nameOfMom.length <= 0 && `${t("Errors.Error_Name_at_mom")}`);
    toast.error(
      nameAtBirth.length <= 0 && `${t("Errors.Error_Name_at_birth")}`
    );
    toast.error(
      cityOfBirth.length <= 0 && `${t("Errors.Error_Place_of_birth")}`
    );
    toast.error(
      dateOfBirth.length <= 0 && `${t("Errors.Error_Date_of_birth")}`
    );
    toast.error(gender.length <= 0 && `${t("Errors.Error_Gender")}`);
    toast.error(idCardNumber.length <= 0 && `${t("Errors.Missing_data")}`);
    toast.error(biometricDays.length <= 0 && `${t("Errors.Error_ID_Card")}`);
    toast.error(nationality.length <= 0 && `${t("Errors.Error_Nationality")}`);
    toast.error(index.length <= 0 && `${t("Errors.Error_Postal_code")}`);
    toast.error(city.length <= 0 && `${t("Errors.Error_City")}`);
    toast.error(street.length <= 0 && `${t("Errors.Error_Street")}`);
    toast.error(itsPublic.length <= 0 && `${t("Errors.Error_Street_type")}`);
    toast.error(
      numberOfHouse.length <= 0 && `${t("Errors.Error_House_number")}`
    );
    toast.error(floor.length <= 0 && `${t("Errors.Error_Floor")}`);
    toast.error(door.length <= 0 && `${t("Errors.Error_Door")}`);
    toast.error(phoneNumber.length <= 0 && `${t("Errors.Error_Phone")}`);
    toast.error(email.length <= 0 && `${t("Errors.Error_Email")}`);
    toast.error(etc1.length <= 0 && `${t("Errors.Missing_data")}`);
    toast.error(etc2.length <= 0 && `${t("Errors.Error_Please_select")}`);
    toast.error(etc3.length <= 0 && `${t("Errors.Error_Please_select")}`);
    toast.error(etc4.length <= 0 && `${t("Errors.Error_Please_dimensions")}`);
    toast.error(etc5.length <= 0 && `${t("Errors.Error_Please_select")}`);
    toast.error(etc6.length <= 0 && `${t("Errors.Error_Please_select")}`);
    toast.error(status.length <= 0 && `${t("Errors.Error_Please_select")}`);
    toast.error(
      accommodation.length <= 0 && `${t("Errors.Error_Please_select")}`
    );
    toast.error(comment.length <= 0 && `${t("Errors.Missing_data")}`);
  };

  /* ----- Select menu - Position ----- */
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    getPositions();
  }, []);

  const getPositions = async () => {
    const response = await axiosInstance.get("/positions");
    setPositions(response.data);
  };

  /* ----- Select menu - Work ----- */
  const [works, setWorks] = useState([]);

  useEffect(() => {
    getWorks();
  }, []);

  const getWorks = async () => {
    const response = await axiosInstance.get("/works");
    setWorks(response.data);
  };

  /* ----- Send Email ----- */
  const sendEmail = async (e) => {
    try {
      const data = {
        firstName: user.firstName,
        lastName: user.lastName,
        name,
        startingPoint,
        work,
        departure,
        position,
      };
      await axiosInstance.post("/new_employee_email", data);
    } catch (error) {
      console.log(error);
    }
  };

  /* ----- TAJ input mask ----- */
  const handleInputTaj = (e) => {
    const formattedTajNumber = formatTajNumber(e.target.value);
    setTaj(formattedTajNumber);
  };

  function formatTajNumber(value) {
    if (!value) return value;
    const taj = value.replace(/[^\d]/g, "");
    const tajNumberLength = taj.length;
    if (tajNumberLength < 4) return taj;
    /* if (tajNumberLength < 7) {
      return `(${taj.slice(0, 3)}) ${taj.slice(3)}`;
    } */
    return `${taj.slice(0, 3)}-${taj.slice(3, 6)}-${taj.slice(6, 9)}`;
  }

  /* ----- Phone input mask ----- */
  const handleInputPhone = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
  };

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 1) return phoneNumber;
    // Add + symbol and max 15 character
    return `+${phoneNumber.slice(0, 15)}`;
  }

  return (
    <section
      id=""
      className="w-full h-[75vh] md:h-[80vh] text-black dark:text-white overflow-scroll no-scrollbar px-1 py-4">
      {/* ----- Alert ----- */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        //theme="light"
      />
      {/* ----- Form ----- */}
      <form
        autoComplete="off"
        onSubmit={saveEmployee}
        className="w-full h-fit flex flex-col justify-start items-start gap-4">
        <div className="w-full h-fit flex justify-center items-center">
          <ModalTitle title={t("Employee.Add_new_employee")} />
        </div>
        {/* ----- Error message from backend ----- */}
        {/* <p className="errorMsg"> {msg}</p> */}
        <div className="relative w-full h-full flex flex-col">
          <select
            translate="no"
            value={work}
            onChange={(e) => setWork(e.target.value)}
            className={`peer w-full h-10 bg-white dark:bg-slate-900 border focus:border-2  ${
              error && work.length <= 0
                ? "border-red-700 dark:border-red-700 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-red-700 dark:text-red-700"
                : "border-gray-500/50 dark:border-gray-300 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-black dark:text-white"
            } text-base font-normal tracking-wider rounded-md outline-none transition-all duration-600 indent-3`}>
            <option>
              {error && work.length <= 0
                ? `${t("Errors.Error_Work")}`
                : `${t("Employee.Work")}`}
            </option>
            {works.map((work) => (
              <option key={work.uuid}>{work.factoryName}</option>
            ))}
          </select>
          {work.length <= 0 ? null : (
            <SelectLabel
              errors={error && work.length <= 0}
              title={t("Employee.Work")}
            />
          )}
        </div>
        <div className="relative w-full h-full flex flex-col">
          <select
            translate="no"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            className={`w-full h-10 bg-white dark:bg-slate-900 border focus:border-2 ${
              error && position.length <= 0
                ? "text-red-700 dark:text-red-700 border-red-700 dark:border-red-700"
                : "border-gray-500/50 dark:border-gray-300 focus:border-system-green hover:border-gray-800 text-black dark:text-white"
            } text-base font-normal tracking-wider rounded-[5px] outline-none indent-3 `}>
            <option>
              {error && position.length <= 0
                ? `${t("Errors.Error_Positions")}`
                : `${t("Employee.Positions")}`}
            </option>
            {positions.map((position) => (
              <option key={position.uuid}>{position.positionName}</option>
            ))}
          </select>
          {position.length <= 0 ? null : (
            <SelectLabel
              errors={error && position.length <= 0}
              title={t("Employee.Positions")}
            />
          )}
        </div>
        <Inputs
          types="date"
          errors={error && departure.length <= 0}
          values={departure}
          onChanges={(e) => setDeparture(e.target.value)}
          title={t("Employee.Travel_Date")}
        />
        <div className="relative w-full h-full flex flex-col">
          <select
            translate="no"
            value={startingPoint}
            onChange={(event) => setStartingPoint(event.target.value)}
            className={`peer w-full h-10 bg-white dark:bg-slate-900 border focus:border-2  ${
              error && startingPoint.length <= 0
                ? "border-red-700 dark:border-red-700 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-red-700 dark:text-red-700"
                : "border-gray-500/50 dark:border-gray-300 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-black dark:text-white"
            } text-base font-normal tracking-wider rounded-md outline-none transition-all duration-600 indent-3`}>
            <option value="">
              {error && startingPoint.length <= 0
                ? `${t("Errors.Error_Country")}`
                : `${t("Employee.startingPoint")}`}
            </option>
            <option value="Ukrajna">{t("Employee.Ukraine")}</option>
            <option value="Magyarország">{t("Employee.Hungary")}</option>
            <option value="Szlovákia">{t("Employee.Slovakia")}</option>
          </select>
          {startingPoint.length <= 0 ? null : (
            <SelectLabel
              errors={error && startingPoint.length <= 0}
              title={t("Employee.startingPoint")}
            />
          )}
        </div>
        <Inputs
          types="text"
          errors={error && name.length <= 0}
          values={name}
          onChanges={(e) => setName(e.target.value)}
          title={t("Employee.Name")}
        />
        <Inputs
          types="text"
          errors={error && vat.length <= 0}
          values={vat.slice(0, 10)}
          onChanges={(e) => setVat(e.target.value)}
          title={t("Employee.TAX")}
        />
        <Inputs
          types="text"
          errors={error && taj.length <= 0}
          values={taj}
          onChanges={(e) => handleInputTaj(e)}
          title={t("Employee.TAJ")}
        />
        <Inputs
          types="text"
          errors={error && idNumber.length <= 0}
          values={idNumber}
          onChanges={(e) => setIdNumber(e.target.value)}
          title={t("Employee.ID_Number1")}
        />
        <Inputs
          types="text"
          errors={error && vatNumber.length <= 0}
          values={vatNumber}
          onChanges={(e) => setVatNumber(e.target.value)}
          title={t("Employee.ID_Number2")}
        />
        <Inputs
          types="text"
          errors={error && nameOfMom.length <= 0}
          values={nameOfMom}
          onChanges={(e) => setNameOfMom(e.target.value)}
          title={t("Employee.Name_at_mom")}
        />
        <Inputs
          types="text"
          errors={error && nameAtBirth.length <= 0}
          values={nameAtBirth}
          onChanges={(e) => setNameAtBirth(e.target.value)}
          title={t("Employee.Name_at_birth")}
        />
        <Inputs
          types="text"
          errors={error && cityOfBirth.length <= 0}
          values={cityOfBirth}
          onChanges={(e) => setCityOfBirth(e.target.value)}
          title={t("Employee.Place_of_birth")}
        />
        <Inputs
          types="date"
          errors={error && dateOfBirth.length <= 0}
          values={dateOfBirth}
          onChanges={(e) => setDateOfBirth(e.target.value)}
          title={t("Employee.Date_of_birth")}
        />
        <div className="relative w-full h-full flex flex-col">
          <select
            value={gender}
            onChange={(event) => setGender(event.target.value)}
            className={`peer w-full h-10 bg-white dark:bg-slate-900 border focus:border-2  ${
              error && gender.length <= 0
                ? "border-red-700 dark:border-red-700 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-red-700 dark:text-red-700"
                : "border-gray-500/50 dark:border-gray-300 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-black dark:text-white"
            } text-base font-normal tracking-wider rounded-md outline-none transition-all duration-600 indent-3`}>
            <option value="">{t("Employee.Gender")}</option>
            <option value="Férfi">{t("Employee.Man")}</option>
            <option value="Nő">{t("Employee.Woman")}</option>
          </select>
          {gender.length <= 0 ? null : (
            <SelectLabel
              errors={error && gender.length <= 0}
              title={t("Employee.Gender")}
            />
          )}
        </div>
        <Inputs
          types="text"
          errors={error && idCardNumber.length <= 0}
          values={idCardNumber}
          onChanges={(e) => setIdCardNumber(e.target.value)}
          title={t("Employee.ID_Card")}
        />
        <Inputs
          types="text"
          errors={error && biometricDays.length <= 0}
          values={biometricDays}
          onChanges={(e) => setBiometricDays(e.target.value)}
          title={t("Employee.Remaining_biometric_days")}
        />
        <div className="relative w-full h-full flex flex-col">
          <select
            value={nationality}
            onChange={(event) => setNationality(event.target.value)}
            className={`peer w-full h-10 bg-white dark:bg-slate-900 border focus:border-2  ${
              error && nationality.length <= 0
                ? "border-red-700 dark:border-red-700 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-red-700 dark:text-red-700"
                : "border-gray-500/50 dark:border-gray-300 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-black dark:text-white"
            } text-base font-normal tracking-wider rounded-md outline-none transition-all duration-600 indent-3`}>
            <option value="">{t("Employee.Nationality")}</option>
            <option value="Magyar">{t("Employee.Hungarian")}</option>
            <option value="Ukrán">{t("Employee.Ukrainian")}</option>
          </select>
          {nationality.length <= 0 ? null : (
            <SelectLabel
              errors={error && nationality.length <= 0}
              title={t("Employee.Nationality")}
            />
          )}
        </div>
        <Inputs
          types="text"
          errors={error && index.length <= 0}
          values={index}
          onChanges={(e) => setIndex(e.target.value)}
          title={t("Employee.Postal_code")}
        />
        <Inputs
          types="text"
          errors={error && city.length <= 0}
          values={city}
          onChanges={(e) => setCity(e.target.value)}
          title={t("Employee.City")}
        />
        <Inputs
          types="text"
          errors={error && street.length <= 0}
          values={street}
          onChanges={(e) => setStreet(e.target.value)}
          title={t("Employee.Street")}
        />
        <Inputs
          types="text"
          errors={error && itsPublic.length <= 0}
          values={itsPublic}
          onChanges={(e) => setItsPublic(e.target.value)}
          title={t("Employee.Street_type")}
        />
        <Inputs
          types="text"
          errors={error && numberOfHouse.length <= 0}
          values={numberOfHouse}
          onChanges={(e) => setNumberOfHouse(e.target.value)}
          title={t("Employee.House_number")}
        />
        <Inputs
          types="text"
          errors={error && floor.length <= 0}
          values={floor}
          onChanges={(e) => setFloor(e.target.value)}
          title={t("Employee.Floor")}
        />
        <Inputs
          types="text"
          errors={error && door.length <= 0}
          values={door}
          onChanges={(e) => setDoor(e.target.value)}
          title={t("Employee.Door")}
        />
        <Inputs
          types="text"
          errors={error && phoneNumber.length <= 0}
          values={phoneNumber}
          onChanges={(e) => handleInputPhone(e)}
          title={t("Employee.Phone")}
        />
        <Inputs
          types="email"
          errors={error && email.length <= 0}
          values={email}
          onChanges={(e) => setEmail(e.target.value)}
          title={t("Employee.Email")}
        />
        <div className="relative w-full h-full flex flex-col">
          <select
            value={etc2}
            onChange={(event) => setEtc2(event.target.value)}
            className={`peer w-full h-10 bg-white dark:bg-slate-900 border focus:border-2  ${
              error && etc2.length <= 0
                ? "border-red-700 dark:border-red-700 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-red-700 dark:text-red-700"
                : "border-gray-500/50 dark:border-gray-300 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-black dark:text-white"
            } text-base font-normal tracking-wider rounded-md outline-none transition-all duration-600 indent-3`}>
            <option value="">{t("Employee.Its_designation")}</option>
            <option value="Nincs">{t("Employee.There_are")}</option>
            <option value="Házastárs">{t("Employee.Spouse")}</option>
            <option value="Ismerős">{t("Employee.Familiar")}</option>
            <option value="Családtag">{t("Employee.Family_member")}</option>
          </select>
          {etc2.length <= 0 ? null : (
            <SelectLabel
              errors={error && etc2.length <= 0}
              title={t("Employee.Its_designation")}
            />
          )}
        </div>
        <Inputs
          types="text"
          errors={error && etc1.length <= 0}
          values={etc1}
          onChanges={(e) => setEtc1(e.target.value)}
          title={t("Employee.Partners_name")}
        />
        <div className="relative w-full h-full flex flex-col">
          <select
            value={etc3}
            onChange={(e) => setEtc3(e.target.value)}
            className={`peer w-full h-10 bg-white dark:bg-slate-900 border focus:border-2  ${
              error && etc3.length <= 0
                ? "border-red-700 dark:border-red-700 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-red-700 dark:text-red-700"
                : "border-gray-500/50 dark:border-gray-300 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-black dark:text-white"
            } text-base font-normal tracking-wider rounded-md outline-none transition-all duration-600 indent-3`}>
            <option value="">{t("Employee.Spoken_languages")}</option>
            <option value="Ukrán">{t("Employee.UA")}</option>
            <option value="Magyar">{t("Employee.HU")}</option>
            <option value="Ukrán-Magyar">{t("Employee.UA_HU")}</option>
            <option value="Ukrán-Magyar-Angol">{t("Employee.UA_HU_EN")}</option>
            <option value="Ukrán-Angol">{t("Employee.UA_EN")}</option>
            <option value="Magyar-Angol">{t("Employee.HU_EN")}</option>
          </select>
          {etc3.length <= 0 ? null : (
            <SelectLabel
              errors={error && etc3.length <= 0}
              title={t("Employee.Spoken_languages")}
            />
          )}
        </div>
        <Inputs
          types="text"
          errors={error && etc4.length <= 0}
          values={etc4}
          onChanges={(e) => setEtc4(e.target.value)}
          title={t("Employee.Size_shirt")}
        />
        <Inputs
          types="text"
          errors={error && etc4Shoe.length <= 0}
          values={etc4Shoe}
          onChanges={(e) => setEtc4Shoe(e.target.value)}
          title={t("Employee.Size_shoe")}
        />
        <div className="relative w-full h-full flex flex-col">
          <select
            value={etc5}
            onChange={(e) => setEtc5(e.target.value)}
            className={`peer w-full h-10 bg-white dark:bg-slate-900 border focus:border-2  ${
              error && etc5.length <= 0
                ? "border-red-700 dark:border-red-700 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-red-700 dark:text-red-700"
                : "border-gray-500/50 dark:border-gray-300 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-black dark:text-white"
            } text-base font-normal tracking-wider rounded-md outline-none transition-all duration-600 indent-3`}>
            <option value="">{t("Employee.Education")}</option>
            <option value="Középiskolai">{t("Employee.Average")}</option>
            <option value="Technical">{t("Employee.Technical")}</option>
            <option value="Egyetem/Főiskola">{t("Employee.Higher")}</option>
          </select>
          {etc5.length <= 0 ? null : (
            <SelectLabel
              errors={error && etc5.length <= 0}
              title={t("Employee.Education")}
            />
          )}
        </div>
        <Inputs
          types="text"
          errors={error && professional.length <= 0}
          values={professional}
          onChanges={(e) => setProfessional(e.target.value)}
          title={t("Employee.Professional_qualification")}
        />
        <Inputs
          types="text"
          errors={error && previousWorkplace.length <= 0}
          values={previousWorkplace}
          onChanges={(e) => setPreviousWorkplace(e.target.value)}
          title={t("Employee.Previous_workplace")}
        />
        <div className="relative w-full h-full flex flex-col">
          <select
            value={etc6}
            onChange={(e) => setEtc6(e.target.value)}
            className={`peer w-full h-10 bg-white dark:bg-slate-900 border focus:border-2  ${
              error && etc6.length <= 0
                ? "border-red-700 dark:border-red-700 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-red-700 dark:text-red-700"
                : "border-gray-500/50 dark:border-gray-300 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-black dark:text-white"
            } text-base font-normal tracking-wider rounded-md outline-none transition-all duration-600 indent-3`}>
            <option value="">{t("Employee.COVID_Vaccination")}</option>
            <option value="Van">{t("Employee.Yes")}</option>
            <option value="Nincs">{t("Employee.No")}</option>
          </select>
          {etc6.length <= 0 ? null : (
            <SelectLabel
              errors={error && etc6.length <= 0}
              title={t("Employee.COVID_Vaccination")}
            />
          )}
        </div>
        <div className="relative w-full h-full flex flex-col">
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className={`peer w-full h-10 bg-white dark:bg-slate-900 border focus:border-2  ${
              error && status.length <= 0
                ? "border-red-700 dark:border-red-700 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-red-700 dark:text-red-700"
                : "border-gray-500/50 dark:border-gray-300 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-black dark:text-white"
            } text-base font-normal tracking-wider rounded-md outline-none transition-all duration-600 indent-3`}>
            <option value="">{t("Employee.Status")}</option>
            <option value="Active">{t("Employee.Active")}</option>
            <option value="Inactive">{t("Employee.Inactive")}</option>
          </select>
          {status.length <= 0 ? null : (
            <SelectLabel
              errors={error && status.length <= 0}
              title={t("Employee.Status")}
            />
          )}
        </div>
        <div className="relative w-full h-full flex flex-col">
          <select
            value={accommodation}
            onChange={(e) => setAccommodation(e.target.value)}
            className={`peer w-full h-10 bg-white dark:bg-slate-900 border focus:border-2  ${
              error && accommodation.length <= 0
                ? "border-red-700 dark:border-red-700 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-red-700 dark:text-red-700"
                : "border-gray-500/50 dark:border-gray-300 focus:border-system-green dark:focus:border-white hover:border-gray-800 text-black dark:text-white"
            } text-base font-normal tracking-wider rounded-md outline-none transition-all duration-600 indent-3`}>
            <option value="">{t("Employee.Accommodation")}</option>
            <option value="Igen">{t("Employee.Yes")}</option>
            <option value="Nem">{t("Employee.No")}</option>
          </select>
          {accommodation.length <= 0 ? null : (
            <SelectLabel
              errors={error && accommodation.length <= 0}
              title={t("Employee.Accommodation")}
            />
          )}
        </div>
        <Inputs
          types="text"
          errors={error && comment.length <= 0}
          values={comment}
          onChanges={(e) => setComment(e.target.value)}
          title={t("Employee.Comment")}
        />
        {/* ----- Button ----- */}
        <FormButtons //onErrors={handleErrors}
          onClicks={() => setOpen(!open)}
        />
      </form>
    </section>
  );
};

export default EmployeeForm;
