import React from "react";

// Language
import { useTranslation } from "react-i18next";

// Icon
import { GoTrash } from "react-icons/go";

const DeleteButton = ({ onClicks, text, className = "" }) => {
  /* ----- Language ----- */
  const { t } = useTranslation();
  return (
    <button
      aria-label="Delete button"
      onClick={onClicks}
      className={`w-fit h-fit flex flex-row justify-center items-center bg-transparent hover:bg-red-800 border border-red-800 rounded-md text-red-800 dark:text-white hover:text-white text-center text-base font-normal tracking-wider leading-none transition-all duration-150 hover:shadow-lg hover:shadow-blue-400/20 p-[6px] gap-1 ${className}`}>
      <GoTrash /> {t("EmployeeList.Delete")}
    </button>
  );
};

export default DeleteButton;
