import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

// Language
import { useTranslation } from "react-i18next";

// Chart JS
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const DataChart = () => {
  /* ----- Privilege Admin & User ----- */
  const { user } = useSelector((state) => state.auth);

  /* ----- Language ----- */
  const { t } = useTranslation();

  /* ----- React App Api URL ----- */
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  /* ----- Employee data ----- */
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  /* ----- Method fetching data ----- */
  const getEmployees = async () => {
    const response = await axiosInstance.get("/employees");
    setEmployees(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getEmployees();
  }, []);

  //
  /*   const userID = employees.reduce((acc, employee) => {
    return {
      ...acc,
      [employee.userId]: (acc[employee.userId] || 0) + 1,
    };
  }, []); */

  //
  const userName = employees.reduce((acc, employee) => {
    return {
      ...acc,
      [employee.user.lastName]: (acc[employee.user.lastName] || 0) + 1,
    };
  }, []);
  //console.log(userName);

  // Chart JS
  var data = {
    labels: [],
    datasets: [
      {
        label: "",
        data: userName,
        barPercentage: 0.5, // Szélesség
        //barThickness: 40,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)", // Red
          "rgba(54, 162, 235, 0.5)", // Blue
          "rgba(255, 206, 86, 0.5)", // Orange
          "rgba(75, 192, 192, 0.5)", // Green
          "rgba(153, 102, 255, 0.5)", // Violet
          "rgba(0, 122, 0, 0.5)", // Green
          "rgba(251,2,2,0.5)", // Red
          "rgba(16,1,255,0.5)", // Dark Blue
          "rgba(1,255,11,0.5)", // Green
          "rgba(255,203,1,0.7)", // Yellow
          "rgba(165,1,255,0.7)", // Violet
          "rgba(0,212,255,0.5)", // Sky Blue
          "rgba(149,255,1,0.5)", // Yelow-Green
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)", // Red
          "rgba(54, 162, 235, 1)", // Blue
          "rgba(255, 206, 86, 1)", // Orange
          "rgba(75, 192, 192, 1)", // Green
          "rgba(153, 102, 255, 1)", // Violet
          "rgba(0, 122, 0, 1)", // Green
          "rgba(251,2,2,1)", // Red
          "rgba(16,1,255,1)", // Dark Blue
          "rgba(1,255,11,1)", // Green
          "rgba(255,203,1,1)", // Yellow
          "rgba(165,1,255,1)", // Violet
          "rgba(0,212,255,1)", // Sky Blue
          "rgba(149,255,1,1)", // Yelow-Green
        ],
        borderWidth: 1,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
    },
    title: { text: "Minta Szoveg", display: true },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    scales: {
      y: {
        beginAtZero: true,
        scaleLabel: {
          labelString: "Employee",
          display: true,
        },
      },
      x: {
        scaleLabel: {
          labelString: "User",
          display: true,
        },
      },
    },
  };
  return (
    <div className="w-full h-[35vh] min-h-[35vh] bg-white dark:bg-slate-900 border-none rounded-md shadow-lg shadow-blue-400/20 overflow-hidden pb-8 p-4">
      <h2 className="text-center text-base md:text-2xl text-black dark:text-white font-normal font-mont">
        {t("Statistic.Per_recruiter")}
      </h2>
      <Bar data={data} options={options} />
    </div>
  );
};

export default DataChart;
