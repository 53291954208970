import React from "react";

const ModalTitle = ({ title, className = "" }) => {
  return (
    <h2
      className={`text-center text-2xl font-light tracking-wider ${className}`}>
      {title}
    </h2>
  );
};

export default ModalTitle;
