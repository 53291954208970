import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser, reset } from "../../features/authSlice";
import axios from "axios";

// ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha";

// Language
import { useTranslation } from "react-i18next";
import i18n from "i18next";

// Icon
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

// Components
import ForgotPasswordModal from "../../components/modal/forgotPassword/ForgotPasswordModal";

// Images
import BG from "../../assets/img/bg.png";
import ENG1 from "../../assets/icon/gb1.png";
import HUN1 from "../../assets/icon/hu1.png";
import UAH1 from "../../assets/icon/ua1.png";

const Login = () => {
  /* ----- Login ----- */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  /* -----  Redirect ----- */
  useEffect(() => {
    if (user || isSuccess) {
      navigate("/dashboard");
    }
    dispatch(reset());
  }, [user, isSuccess, dispatch, navigate]);

  /* ----- Auth ----- */
  const Auth = (e) => {
    e.preventDefault();
    if (captcha.current.getValue()) {
      dispatch(LoginUser({ email, password }));
      //setUserVerified(true);
      setVerified(true);
      setTimeout(() => {
        //sendEmail(); // Send email after login
      }, 3000);
    } else {
      setUserVerified(false);
      setVerified(false);
    }
  };

  /* ----- Show password ----- */
  const [visible, setVisible] = useState(false);
  const toggleBtn = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  /* ----- Language ----- */
  const { t } = useTranslation();
  function handleChangeLanguage(lang) {
    i18n.changeLanguage(lang);
  }

  /* ----- ReCAPTCHA  -----*/
  const [verified, setVerified] = useState(null);
  const [userVerified, setUserVerified] = useState(false);
  const captcha = useRef(null);
  const handleOnChange = () => {
    if (captcha.current.getValue()) {
    }
    //console.log(captcha.current.getValue());
  };

  /* ----- React App Api URL ----- */
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  /* ----- Send Mail ----- */
  const [send, setSend] = useState(false);

  const sendEmail = async (e) => {
    setSend(true);
    try {
      await axiosInstance.post("/send_login_email", {
        email,
        deviceInfo: browserD(),
        deviceOS: getOsType(),
      });
    } catch (error) {
      console.log(error);
    }
  };

  /* ----- User Agent ----- */
  /*   function deviceInfo() {
    return navigator.userAgent;
  }
  console.log(deviceInfo()); */
  /*    console.log(navigator); */

  /* ----- User GeoLocation and IP ----- */

  /* ----- User Agent Browser ----- */
  const browserD = () => {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  };
  /* console.log(browserD()); */

  const getOsType = () => {
    const ua = navigator.userAgent;
    if (/(iPho)/.test(ua)) {
      return "iOS iPhone";
    }
    if (/(iPa)/.test(ua)) {
      return "iPad OS";
    }
    if (/(And)/.test(ua)) {
      return "Android";
    }
    if (/(Mac)/.test(ua)) {
      return "Mac OS";
    }
    if (/(Win)/.test(ua)) {
      return "Windows";
    }
    if (/(Lin)/.test(ua)) {
      return "Linux";
    }
  };
  /*  console.log(getOsType()); */

  /*   const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "Tablet";
    }
    if (/(Mac)/.test(ua)) {
      return "Mac OS";
    }
    if (/(Win)/.test(ua)) {
      return "Windows";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "Mobile";
    }
    return "Desktop";
  };
  console.log(getDeviceType());
 */

  return (
    <section
      id="login section"
      className="w-full h-screen bg-cover bg-center bg-no-repeat flex justify-center items-center"
      style={{
        background: `url('${BG}')`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}>
      {!userVerified ? (
        <form
          className="w-11/12 md:w-96 min-w-fit h-fit flex flex-col justify-center items-center bg-white/20 backdrop-blur text-white tracking-wider rounded-xl shadow-[0_8px_32px_0_rgba(31,38,135,0.37)] py-4 gap-4"
          onSubmit={Auth}>
          <h2 className="text-4xl text-center font-light">
            {t("Login.Welcome")}
          </h2>
          {/* ----- Error Msg ----- */}
          {isError ? (
            <p className="text-lg text-center font-light border-b-2 border-red-600">
              {message === "Email!"
                ? `${t("Login.Wrong_Email")}`
                : `${t("Login.Wrong_Password")}`}
            </p>
          ) : null}
          {verified === false && (
            <p className="text-lg text-center font-light border-b-2 border-red-600 ">
              {t("Login.Robot")}
            </p>
          )}
          {/* ----- Inputs ----- */}
          <label className="relative w-full h-fit flex justify-center items-center">
            <input
              className={`w-4/5 h-12 bg-white/20 outline-none text-white text-base font-light tracking-wider placeholder:text-white border ${
                message === "Email!" ? "border-red-600" : "border-transparent"
              } rounded-3xl shadow-[0_8px_32px_0_rgba(31,38,135,0.37)] p-4  `}
              type="email"
              placeholder={t("Login.Email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label className="relative w-full h-fit flex justify-center items-center">
            <input
              className={`w-4/5 h-12 bg-white/20 outline-none text-white text-base font-light tracking-wider placeholder:text-white border ${
                message === "Password!"
                  ? "border-red-600"
                  : "border-transparent"
              } rounded-3xl shadow-[0_8px_32px_0_rgba(31,38,135,0.37)] p-4  `}
              type={visible ? "text" : "password"}
              placeholder={t("Login.Password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <i
              className="absolute text-3xl top-1/2 -translate-y-1/2 right-[2.6rem] "
              onClick={toggleBtn}>
              {visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </i>
          </label>
          {/* ----- ReCAPTCHA Start ----- */}
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            theme="dark"
            ref={captcha}
            onChange={handleOnChange}
          />
          {/* ----- Button ----- */}
          <button
            aria-label="button"
            className="w-4/5 h-12 uppercase tracking-[0.2rem] text-sm font-light border-none rounded-3xl cursor-pointer bg-gradient-to-r from-[#14163c] to-[#03217b] shadow-[0_8px_32px_0_rgba(31,38,135,0.37)] ">
            {isLoading ? `${t("Login.Loading")}` : `${t("Login.Login")}`}
          </button>
          {/* ----- Language Switch ----- */}
          <div className="flex flex-col justify-center items-center gap-2">
            <p className="text-xl text-center drop-shadow-sm font-extralight ">
              {t("Login.Language")}
            </p>
            <div className="flex flex-row justify-center items-center gap-2">
              <button
                aria-label="english language button"
                onClick={() => handleChangeLanguage("en")}
                className="bg-transparent border-none cursor-pointer">
                <img
                  src={ENG1}
                  alt="language"
                  className=" w-6 h-6 cursor-pointer"
                />
              </button>
              <button
                aria-label="hungarishe language button"
                onClick={() => handleChangeLanguage("hu")}
                className="bg-transparent border-none cursor-pointer">
                <img
                  src={HUN1}
                  alt="language"
                  className=" w-6 h-6 cursor-pointer"
                />
              </button>
              <button
                aria-label="ukraine language button"
                onClick={() => handleChangeLanguage("ua")}
                className="bg-transparent border-none cursor-pointer">
                <img
                  src={UAH1}
                  alt="language"
                  className=" w-6 h-6 cursor-pointer"
                />
              </button>
            </div>
          </div>
          {/* ----- Forgot password ----- */}
          <ForgotPasswordModal />
        </form>
      ) : null}
    </section>
  );
};

export default Login;
