import React from "react";

const CardSkeleton = ({ className = "" }) => {
  return (
    <div
      className={`bg-neutral-200/50 dark:bg-slate-900 ${className} overflow-hidden`}>
      <div className="w-full h-full bg-gradient-to-r from-transparent via-neutral-50 dark:via-slate-800 to-transparent animate-[shimmer_1.5s_infinite] -translate-x-full inset-0"></div>
    </div>
  );
};

export default CardSkeleton;
